import { useAction } from '@/Actions/useAction';

// actions
import BuildBasicShapeAction from '@/Actions/BuildBasicShape';
import UIFeatureAction from '@/Actions/UIFeature';

// components
import CanvasActionMenu from '../CanvasActionMenu';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

// types of shapes to insert
const SHAPE_TYPES = [
  {
    id: Shape.CIRCLE,
    label: 'Circle',
    icon: 'basic-shape-circle',
    i18nKey: 'circle',
  },
  {
    id: Shape.ELLIPSE,
    label: 'Ellipse',
    icon: 'basic-shape-ellipse',
    i18nKey: 'ellipse',
  },
  {
    id: Shape.RECTANGLE,
    label: 'Rectangle',
    icon: 'basic-shape-rectangle',
    i18nKey: 'rectangle',
  },
  {
    id: Shape.ROUNDED_RECT,
    label: 'Rounded Rectangle',
    icon: 'basic-shape-rounded-rectangle',
    i18nKey: 'rounded-rectangle',
  },
  {
    id: Shape.POLYGON,
    label: 'Polygon',
    icon: 'basic-shape-polygon',
    i18nKey: 'polygon',
  },
] as const;

type Props = {
  edge: 'left' | 'right';
};

export default function BasicShapeCreatorActionMenu(props: Props) {
  const buildBasicShape = useAction(BuildBasicShapeAction);
  const uiFeature = useAction(UIFeatureAction);

  // get the shapes to show
  let shapes = [...SHAPE_TYPES];

  // handles requesting a new shape to add
  function onSelectAction(shape: (typeof SHAPE_TYPES)[number]) {
    buildBasicShape.build(shape.id);
    uiFeature.toggleEditSelectionProperties(true);
  }

  return (
    <CanvasActionMenu
      onSelectAction={onSelectAction}
      edge={props.edge}
      className='shape-creator-menu'
      actions={shapes}
    />
  );
}

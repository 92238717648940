import React from 'react';

// components
import Icon from '@/Styles/Icons/Icon';
import TranslationText from '@/Components/TranslationText/TranslationText';

// helpers
import classNames from 'classnames';
import { ToggleButtonOption } from '../ToggleButton';

export type TabProps = {
  label: string;
  icon: string;
  selected: boolean;
  i18nKey: string;
  dataCy?: string;
  onClick: (opt: ToggleButtonOption) => void;
};

export default function Tab(props: TabProps) {
  const { label, icon, selected: isSelected, i18nKey, dataCy, onClick } = props;

  const tabCx = classNames('toggle-button--tab', {
    selected: isSelected,
  });

  const iconCx = classNames('toggle-button--tab--icon', {
    selected: isSelected,
  });

  const textCx = classNames('toggle-button--tab--text', {
    selected: isSelected,
  });

  const doOnClick = () => {
    onClick({
      label,
      icon,
      i18nKey,
    });
  };

  return (
    <div className={tabCx} onClick={doOnClick} data-cy={dataCy}>
      <Icon className={iconCx} icon={icon} />
      <div className={textCx}>
        <TranslationText i18nKey={i18nKey}>{label}</TranslationText>
      </div>
    </div>
  );
}

import { subtract, add, scalarMul, createPointFromUnits } from './PointOps';
import {
  AABB,
  unitAABB,
  transform,
  mergeAABBs,
  mergeAABBArray,
  getAABBSize,
} from '@shapertools/sherpa-svg-generator/AABB';
import { Point } from '@shapertools/sherpa-svg-generator/Point';

const defaultSize = createPointFromUnits('1 in', '1 in');

export interface AABBBounds {
  left: number;
  right: number;
  top: number;
  bottom: number;
  size: Point;
  center: Point;
  points: Point[];
}

function clone(aabb: AABB): AABB {
  return new AABB({
    minPoint: new Point(aabb.minPoint.x, aabb.minPoint.y),
    maxPoint: new Point(aabb.maxPoint.x, aabb.maxPoint.y),
  });
}

function updateAABBFromCenterAndSize(
  currentAABB: AABB = new AABB(),
  centerPosition: Point = new Point(),
  size: Point = defaultSize
): AABB {
  const newAABB = new AABB({
    minPoint: subtract(centerPosition, scalarMul(size, 0.5)),
    maxPoint: add(centerPosition, scalarMul(size, 0.5)),
  });

  return mergeAABBs(currentAABB, newAABB);
}

function getSizeAndCenterFromAABB(currentAABB: AABB = new AABB()): {
  size: Point;
  centerPosition: Point;
} {
  const size = new Point(
    currentAABB.maxPoint.x - currentAABB.minPoint.x,
    currentAABB.maxPoint.y - currentAABB.minPoint.y
  );

  const centerPosition = new Point(
    (currentAABB.maxPoint.x + currentAABB.minPoint.x) / 2,
    (currentAABB.maxPoint.y + currentAABB.minPoint.y) / 2
  );

  return { size, centerPosition };
}

function isPointInAABB(aabb: AABB, point: Point, padding: number = 0) {
  return (
    point.x >= aabb.minPoint.x - padding &&
    point.x <= aabb.maxPoint.x + padding &&
    point.y >= aabb.minPoint.y - padding &&
    point.y <= aabb.maxPoint.y + padding
  );
}

function isOverlapping(
  minA: number,
  maxA: number,
  minB: number,
  maxB: number,
  padding: number = 0
): boolean {
  return (
    (minA >= minB - padding && minA <= maxB + padding) ||
    (maxA >= minB - padding && maxA <= maxB + padding) ||
    (minB >= minA - padding && minB <= maxA + padding) ||
    (maxB >= minA - padding && maxB <= maxA + padding)
  );
}

function doAABBsIntersect(bb0: AABB, bb1: AABB, padding: number = 0): boolean {
  return (
    isOverlapping(
      bb0.minPoint.x,
      bb0.maxPoint.x,
      bb1.minPoint.x,
      bb1.maxPoint.x,
      padding
    ) &&
    isOverlapping(
      bb0.minPoint.y,
      bb0.maxPoint.y,
      bb1.minPoint.y,
      bb1.maxPoint.y,
      padding
    )
  );
}

function getAABBBounds(aabb: AABB): AABBBounds {
  const left = aabb.minPoint.x;
  const right = aabb.maxPoint.x;
  const bottom = aabb.maxPoint.y;
  const top = aabb.minPoint.y;
  const { size, centerPosition: center } = getSizeAndCenterFromAABB(aabb);

  return {
    left,
    right,
    top,
    bottom,
    size,
    center,
    points: [
      new Point(left, top),
      new Point(left, bottom),
      new Point(right, bottom),
      new Point(right, top),
    ],
  };
}

export {
  unitAABB,
  transform,
  getAABBSize,
  clone,
  mergeAABBArray,
  mergeAABBs,
  updateAABBFromCenterAndSize,
  getSizeAndCenterFromAABB,
  isPointInAABB,
  isOverlapping,
  doAABBsIntersect,
  getAABBBounds,
};

import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

// material-ui icons
import SvgIcon from '@mui/material/SvgIcon';
import PublishIcon from '@mui/icons-material/Publish';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UpdateIcon from '@mui/icons-material/Update';

// svg icons
import AspectRatioIcon from './aspect-ratio.svg?react';
import AspectRatioDisabledIcon from './aspect-ratio-disabled.svg?react';
import ExperimentalWatermarkIcon from './experimental.svg?react';
import LineIcon from './line.svg?react';
import IncrementIcon from './increment.svg?react';
import DecrementIcon from './decrement.svg?react';
import CutTypeOnlineIcon from './cut-type-online.svg?react';
import CutTypeInsideIcon from './cut-type-inside.svg?react';
import CutTypeOutsideIcon from './cut-type-outside.svg?react';
import CutTypePocketIcon from './cut-type-pocket.svg?react';
import CutTypeGuideIcon from './cut-type-guide.svg?react';
import CutTypeIcon from './cut-type.svg?react';
import DepthIcon from './depth.svg?react';
import DrawIcon from './draw.svg?react';
import OffsetIcon from './offset.svg?react';
import BitDiameterIcon from './bit-diameter.svg?react';
import UnitINIcon from './unit-in.svg?react';
import UnitMMIcon from './unit-mm.svg?react';
import UnitTypeIcon from './unit-type.svg?react';
import DeleteIcon from './delete.svg?react';
import CheckActiveIcon from './check-active.svg?react';
import CheckInactiveIcon from './check-inactive.svg?react';
import CheckIcon from './check.svg?react';
import SearchIcon from './search.svg?react';
import FontIcon from './font.svg?react';
import DuplicateIcon from './duplicate.svg?react';
import GridIcon from './grid.svg?react';
import ToolPreviewIcon from './tool-preview.svg?react';
import BuildShapeIcon from './build-shape.svg?react';
import EditShapeIcon from './edit-shape.svg?react';
import ShapeShifterIcon from './shape-shifter.svg?react';
import RotationIcon from './rotation.svg?react';
import LinkedIcon from './linked.svg?react';
import UnlinkedIcon from './unlinked.svg?react';
import CutIcon from './cut.svg?react';
import DownloadIcon from './download.svg?react';
import CloudIcon from './cloud.svg?react';
import EditTextIcon from './edit-text.svg?react';
import SaveIcon from './save.svg?react';
import SizeIcon from './size.svg?react';
import MirrorHorizontalIcon from './mirror-horizontal.svg?react';
import MirrorVerticalIcon from './mirror-vertical.svg?react';
import SwapHorizontalIcon from './swap-horizontal.svg?react';
import WarningIcon from './warning.svg?react';
import FeedbackIcon from './feedback.svg?react';
import RightChevronIcon from './right-chevron.svg?react';
import HelpIcon from './help.svg?react';
import EraseIcon from './erase.svg?react';
import DiameterIcon from './diameter.svg?react';
import RadiusIcon from './radius.svg?react';
import PositionIcon from './position.svg?react';
import OpenInNewWindowIcon from './open-in-new-window.svg?react';
import LoadingIndicatorIcon from './loading-indicator.svg?react';
import CanvasActionSearchIcon from './canvas-action-search.svg?react';
import CanvasActionShapesIcon from './canvas-action-shapes.svg?react';
import CanvasActionTextIcon from './canvas-action-text.svg?react';
import CanvasActionTraceIcon from './canvas-action-trace.svg?react';
import CanvasActionUploadIcon from './canvas-action-upload.svg?react';
import BasicShapeCircle from './basic-shape-circle.svg?react';
import BasicShapeEllipse from './basic-shape-ellipse.svg?react';
import BasicShapeRectangle from './basic-shape-rectangle.svg?react';
import BasicShapeRoundedRectangle from './basic-shape-rounded-rectangle.svg?react';
import BasicShapePolygon from './basic-shape-polygon.svg?react';
import NewFeaturesIcon from './new-features.svg?react';
import EnterKeyIcon from './enter-key.svg?react';
import UndoIcon from './undo.svg?react';
import RedoIcon from './redo.svg?react';
import TranslateRightIcon from './translate-right.svg?react';
import TranslateUpIcon from './translate-up.svg?react';
import AddShapeIcon from '@/Styles/Icons/add-shape.svg?react';
import ModeDesign from './mode-design.svg?react';
import ModePlan from './mode-plan.svg?react';
import ModeReview from './mode-review.svg?react';
import ModeTabCollapsed from './mode-tab-collapsed.svg?react';
import ModeTabExpanded from './mode-tab-expanded.svg?react';
import ModeOptionsMenuCollapsed from './mode-options-menu-collapsed.svg?react';
import ModeOptionsMenuExpanded from './mode-options-menu-expanded.svg?react';
import ActionMenuToggle from './action-menu-toggle.svg?react';
import SnappingEnabled from './snapping-enabled.svg?react';
import PositioningEnabled from './positioning-enabled.svg?react';
import CutPathsEnabled from './cut-paths-enabled.svg?react';
import SnappingDisabled from './snapping-disabled.svg?react';
import PositioningDisabled from './positioning-disabled.svg?react';
import CutPathsDisabled from './cut-paths-disabled.svg?react';
import NewDesignIcon from './new-design.svg?react';
import OpenIcon from './open.svg?react';
import MoveToProjectIcon from './move-to-project.svg?react';
import ShowInProjectIcon from './show-in-project.svg?react';
import SettingsIcon from './settings.svg?react';
import FolderIcon from './folder.svg?react';
import PeopleIcon from './people.svg?react';
import ShopIcon from './shop.svg?react';
import ImportFileIcon from './import-file.svg?react';
import ImportTogetherIcon from './import_together.svg?react';
import ImportSeparatelyIcon from './import_separately.svg?react';
import ProjectsIcon from './projects.svg?react';
import ShaperOriginIcon from './shaper-origin.svg?react';
import ArrowLeftIcon from './arrow-left.svg?react';
import ChevronLeftIcon from './chevron-left.svg?react';
import ChevronRightIcon from './chevron-right.svg?react';
import ChevronUpIcon from './chevron-up.svg?react';
import ChevronDownIcon from './chevron-down.svg?react';
import MissingIcon from '@/Styles/Icons/missing.svg?react';
import BitDiameter_5 from './bit-diameter-5.svg?react';
import BitDiameter_02 from './bit-diameter-02.svg?react';
import EyeIcon from './eye.svg?react';
import RecenterIcon from './recenter.svg?react';
import ZoomToSelectionIcon from './zoom-to-selection.svg?react';
import QuestionMarkIcon from './question-mark.svg?react';
import QuestionMarkCircleIcon from './question-mark-circle.svg?react';
import QuestionMarkCircleInverseIcon from './question-mark-circle-inverse.svg?react';
import ShortcutsIcon from './shortcuts.svg?react';
import FeedbackClosedIcon from './feedback-closed.svg?react';
import ForumIcon from './forum.svg?react';
import VideoIcon from './video.svg?react';
import ArrowRightIcon from './arrow-right.svg?react';
import ShaperIcon from './shaper-icon.svg?react';
import VisibleEyeIcon from './visible-eye.svg?react';
import MyProjectsIcon from './my-projects.svg?react';
import FileIcon from './file.svg?react';
import StudioFileIcon from './studio-file.svg?react';
import PremiumIcon from './premium.svg?react';
import RecentsIcon from './recents.svg?react';
import PersonIcon from './person.svg?react';
import LockIcon from './lock.svg?react';
import LockWithHoleWhiteIcon from './lock-with-hole-white.svg?react';
import LockWithHoleBlackIcon from './lock-with-hole-black.svg?react';
import SyncProblemIcon from './sync-problem.svg?react';
import MoreVerticalIcon from './more-vertical.svg?react';
import OfflineIcon from './offline.svg?react';
import AlertWarningIcon from './alert-warning.svg?react';
import LoadingDominoIcon from './loading-domino.svg?react';
import HouseIcon from './house.svg?react';
import DevicesIcon from './devices.svg?react';
import SidesIcon from './sides.svg?react';
import SvgFileIcon from './svg.svg?react';
import ExternalIcon from './external.svg?react';
import ShaperHubIcon from './shaperhub.svg?react';
import ExplodedIcon from './exploded.svg?react';
import AllTogetherIcon from './all-together.svg?react';
import MenuDuplicateIcon from './menu-duplicate.svg?react';
import SpinningArrowsIcon from './spinning-arrows.svg?react';
import TimelapseIcon from './timelapse.svg?react';
import InfoIcon from './info.svg?react';
import TraceIcon from './trace.svg?react';
import PathDesignIcon from './path-design.svg?react';
import PathReferenceIcon from './path-guide.svg?react';
import PathCutIcon from './path-cutting.svg?react';
import GuideEnabledIcon from './guide-enabled.svg?react';
import GuideDisabledIcon from './guide-disabled.svg?react';
import LayersIcon from './layers.svg?react';
import EyeActiveIcon from './eye-active.svg?react';
import EyeDisabledActiveIcon from './eye-disabled-active.svg?react';
import EyeInactiveIcon from './eye-inactive.svg?react';
import PathTypeLockIcon from './path-type-lock.svg?react';
import CircleWarningIcon from './circle-warning.svg?react';
import FontsSingleLine from './fonts_singleline.svg?react';
import FontsSerif from './fonts_serif.svg?react';
import FontsSansSerif from './fonts_sansserif.svg?react';
import FontsScript from './fonts_script.svg?react';
import FontsHandwriting from './fonts_handwriting.svg?react';
import FontsNovelty from './fonts_novelty.svg?react';
import FontsNonLatin from './fonts_nonlatin.svg?react';
import UnfoldMore from './unfold_more.svg?react';
import UnfoldLess from './unfold_less.svg?react';
import StudioLogo from './logo.svg?react';
import ViewPageIcon from './view-page.svg?react';

const MAPPINGS = {
  'add-shape': AddShapeIcon,
  'arrow-back': ArrowBackIcon,
  'arrow-left': ArrowLeftIcon,
  'arrow-right': ArrowRightIcon,
  'aspect-ratio': AspectRatioIcon,
  'aspect-ratio-disabled': AspectRatioDisabledIcon,
  'build-shape': BuildShapeIcon,
  'clear-all': ClearAllIcon,
  close: CloseIcon,
  line: LineIcon,
  cloud: CloudIcon,
  cut: CutIcon,
  delete: DeleteIcon,
  done: DoneIcon,
  download: DownloadIcon,
  draw: DrawIcon,
  edit: EditShapeIcon,
  'import-file': ImportFileIcon,
  'import-together': ImportTogetherIcon,
  'import-separately': ImportSeparatelyIcon,
  file: FileIcon,
  'studio-file': StudioFileIcon,
  projects: ProjectsIcon,
  'my-projects': MyProjectsIcon,
  recenter: RecenterIcon,
  'zoom-to-selection': ZoomToSelectionIcon,
  grid: GridIcon,
  settings: SettingsIcon,
  'mirror-horizontal': MirrorHorizontalIcon,
  'mirror-vertical': MirrorVerticalIcon,
  'shaper-origin': ShaperOriginIcon,
  folder: FolderIcon,
  people: PeopleIcon,
  linked: LinkedIcon,
  shop: ShopIcon,
  'open-in-new-window': OpenInNewWindowIcon,
  'right-chevron': RightChevronIcon,
  help: HelpIcon,
  'enter-key': EnterKeyIcon,
  'question-mark': QuestionMarkIcon,
  'question-mark-circle': QuestionMarkCircleIcon,
  'question-mark-circle-inverse': QuestionMarkCircleInverseIcon,
  'bit-diameter-5': BitDiameter_5,
  'bit-diameter-508': BitDiameter_02,
  feedback: FeedbackIcon,
  'feedback-closed': FeedbackClosedIcon,
  'loading-indicator': LoadingIndicatorIcon,
  'loading-domino': LoadingDominoIcon,
  menu: MenuIcon,
  publish: PublishIcon,
  rotation: RotationIcon,
  'edit-text': EditTextIcon,
  'new-design': NewDesignIcon,
  open: OpenIcon,
  'move-to-project': MoveToProjectIcon,
  'show-in-project': ShowInProjectIcon,
  save: SaveIcon,
  font: FontIcon,
  undo: UndoIcon,
  redo: RedoIcon,
  erase: EraseIcon,
  radius: RadiusIcon,
  diameter: DiameterIcon,
  search: SearchIcon,
  size: SizeIcon,
  position: PositionIcon,
  'select-all': SelectAllIcon,
  'cut-type-online': CutTypeOnlineIcon,
  'cut-type-inside': CutTypeInsideIcon,
  'cut-type-outside': CutTypeOutsideIcon,
  'cut-type-pocket': CutTypePocketIcon,
  'cut-type-guide': CutTypeGuideIcon,
  'cut-type': CutTypeIcon,
  depth: DepthIcon,
  offset: OffsetIcon,
  'bit-diameter': BitDiameterIcon,
  'swap-horizontal': SwapHorizontalIcon,
  'shape-shifter': ShapeShifterIcon,
  svg: SvgIcon,
  duplicate: DuplicateIcon,
  'tool-preview': ToolPreviewIcon,
  unlinked: UnlinkedIcon,
  warning: WarningIcon,
  experimental: ExperimentalWatermarkIcon,
  'new-features': NewFeaturesIcon,
  'translate-right': TranslateRightIcon,
  'translate-up': TranslateUpIcon,
  missing: MissingIcon,
  shortcuts: ShortcutsIcon,
  forum: ForumIcon,
  video: VideoIcon,
  shaper: ShaperIcon,
  premium: PremiumIcon,
  recents: RecentsIcon,
  person: PersonIcon,
  'shaper-icon': ShaperIcon,
  devices: DevicesIcon,
  sides: SidesIcon,
  'svg-file': SvgFileIcon,
  external: ExternalIcon,
  'shaper-hub': ShaperHubIcon,
  exploded: ExplodedIcon,
  'all-together': AllTogetherIcon,
  'duplicate-workspace': MenuDuplicateIcon,
  'spinning-arrows': SpinningArrowsIcon,
  info: InfoIcon,
  trace: TraceIcon,
  'path-design': PathDesignIcon,
  'path-reference': PathReferenceIcon,
  'path-cut': PathCutIcon,
  'path-type-lock': PathTypeLockIcon,
  'circle-warning': CircleWarningIcon,

  'chevron-up': ChevronUpIcon,
  'chevron-down': ChevronDownIcon,
  'chevron-right': ChevronRightIcon,
  'chevron-left': ChevronLeftIcon,

  // misc
  'action-menu-toggle': ActionMenuToggle,
  'unit-in': UnitINIcon,
  'unit-mm': UnitMMIcon,
  'unit-type': UnitTypeIcon,
  eye: EyeIcon,
  'visible-eye': VisibleEyeIcon,
  lock: LockIcon,
  'lock-with-hole-white': LockWithHoleWhiteIcon,
  'lock-with-hole-black': LockWithHoleBlackIcon,
  house: HouseIcon,
  timelapse: TimelapseIcon,

  // modes
  'mode-design': ModeDesign,
  'mode-plan': ModePlan,
  'mode-review': ModeReview,
  'mode-tab-collapsed': ModeTabCollapsed,
  'mode-tab-expanded': ModeTabExpanded,
  'mode-options-menu-expanded': ModeOptionsMenuExpanded,
  'mode-options-menu-collapsed': ModeOptionsMenuCollapsed,
  'snapping-enabled': SnappingEnabled,
  'positioning-enabled': PositioningEnabled,
  'cut-paths-enabled': CutPathsEnabled,
  'guide-enabled': GuideEnabledIcon,
  'snapping-disabled': SnappingDisabled,
  'positioning-disabled': PositioningDisabled,
  'cut-paths-disabled': CutPathsDisabled,
  'guide-disabled': GuideDisabledIcon,
  'layers-enabled': LayersIcon,
  'check-inactive': CheckInactiveIcon,
  'check-active': CheckActiveIcon,
  check: CheckIcon,
  'eye-inactive': EyeInactiveIcon,
  'eye-active': EyeActiveIcon,
  'eye-disabled-active': EyeDisabledActiveIcon,

  'canvas-action-search': CanvasActionSearchIcon,
  'canvas-action-shapes': CanvasActionShapesIcon,
  'canvas-action-text': CanvasActionTextIcon,
  'canvas-action-upload': CanvasActionUploadIcon,
  'canvas-action-trace': CanvasActionTraceIcon,

  'basic-shape-circle': BasicShapeCircle,
  'basic-shape-ellipse': BasicShapeEllipse,
  'basic-shape-polygon': BasicShapePolygon,
  'basic-shape-rectangle': BasicShapeRectangle,
  'basic-shape-rounded-rectangle': BasicShapeRoundedRectangle,

  increment: IncrementIcon,
  decrement: DecrementIcon,

  //workspace alert icons
  'sync-problem': SyncProblemIcon,
  'more-vertical': MoreVerticalIcon,
  offline: OfflineIcon,
  'alert-warning': AlertWarningIcon,

  'fonts-single-line': FontsSingleLine,
  'fonts-serif': FontsSerif,
  'fonts-sans-serif': FontsSansSerif,
  'fonts-script': FontsScript,
  'fonts-handwriting': FontsHandwriting,
  'fonts-novelty': FontsNovelty,
  'fonts-non-latin': FontsNonLatin,

  'unfold-more': UnfoldMore,
  'unfold-less': UnfoldLess,
  'studio-logo': StudioLogo,
  'view-page': ViewPageIcon,
  update: UpdateIcon,
  'content-copy': ContentCopyIcon,
} as const;

type IconNames = keyof typeof MAPPINGS;

type Props = {
  icon?: unknown;
  className?: string;
  onClick?: () => void;
} & PropsWithChildren;

export default function Icon(props: Props) {
  const { icon, children, className } = props;
  const iconName =
    icon && (icon as string) in MAPPINGS ? (icon as IconNames) : undefined;
  const childrenIconName =
    typeof children === 'string' && children in MAPPINGS
      ? (children as IconNames)
      : undefined;
  const Instance = iconName
    ? MAPPINGS[iconName]
    : childrenIconName
    ? MAPPINGS[children as IconNames]
    : null;

  const iconCx = classNames('icon', {
    ...(className && { [className]: className }),
  });
  const map = {
    ...props,
    children: undefined,
    className: iconCx,
  };

  return Instance ? (
    <Instance {...map} />
  ) : (
    <MissingIcon
      {...map}
      title={(icon as string) || childrenIconName || 'missing-icon'}
    />
  );
}

import BaseInteraction from '@/InteractionsManager/Interactions/BaseInteraction';

// consts
import { SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY } from '../../../../Constants/UI';

// util
// import { signedArea } from '../../../../Geometry/PathOps';
import * as hoverStateHelper from '../../../../Components/HoverStateHelper/HoverStateHelper';

// selectors
import { selectSelectedPathIds } from '@/Redux/Slices/SelectionSlice';
import { addAttributeToUser } from '../../../../Utility/userflow';

// actions
import SetSelectionAction from '@/Actions/SetSelection';
import SetCursorAction from '../../../../Actions/SetCursor';
import PathHitDetection from '@/Utility/HitDetectionV2';

export default class SelectPathInteraction extends BaseInteraction {
  refreshHoverState = (
    position,
    over = this.hitTestPaths(position),
    isTouch
  ) => {
    const { useSelector } = this;

    // update for hovers
    const selection = useSelector(selectSelectedPathIds);

    // try and find the next selection
    let lastIndex = -1;
    for (let i = 0; i < over.length; i++) {
      if (
        selection.findIndex((selected) => selected.pathId === over[i].pathId) >
        -1
      ) {
        lastIndex = i;
      }
    }

    // find the next layer to select
    const next = over[(lastIndex + 1) % over.length];
    if (next) {
      hoverStateHelper.register({
        groupId: next.groupId,
        pathId: next.pathId,
        options: { isTouch },
      });
    } else {
      hoverStateHelper.clear();
    }
  };

  hitTestPaths({ x, y }) {
    const svgGroups = this.getSvgGroups();
    const viewport = this.getViewport();
    const nspf = this.getNonScalingPixelFactor();

    // find the point on the canvas being tested
    const origin = PathHitDetection.convertScreenPointToCanvas(x, y, viewport);
    let matches = [];
    let hasEdges;

    // check each of the groups
    svgGroups.forEach((group) => {
      group.basePathSet.forEach((path) => {
        const hit = PathHitDetection.pointNearPath(group, path.id, origin, {
          viewport,
          extend: 10,
          nspf,
        });

        // if there's an edge in here, we need to mark it as
        // the priority
        if (hit?.edge) {
          hasEdges = true;
        }

        // add to the results
        matches.push(hit);
      });
    });

    // filter out non-matches and cutPaths (if an edge was found)
    matches = matches
      .filter((match) => (hasEdges && match?.edge) || (!hasEdges && !!match))
      // sort by distance
      .sort((a, b) => a.distance - b.distance);

    // return matches, if any
    return matches;
  }

  calculatedAreas = {};

  onPointerMove(event) {
    const over = this.hitTestPaths(event.center);
    this.refreshHoverState(event.center, over);

    // if viewport elements lose focus, clear the hover state
    // this.onViewportUnhover(() => {
    //   this.refreshHoverState(over.center, over);
    // }, 100);
  }

  // checks for events that should append the targeted layer
  // onto the current selection
  shouldAppendToSelection(event) {
    return event.shiftKey || /touch/i.test(event.pointerType) || this.longPress;
  }

  onKeyDown(event) {
    addAttributeToUser('key_pressed', event.key);
    if (this.isKey(event, SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY)) {
      const cursorAction = this.createAction(SetCursorAction);
      cursorAction.toMultiSelect();
    }
  }

  onKeyUp(event) {
    addAttributeToUser('key_pressed', null);
    if (this.isKey(event, SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY)) {
      const cursorAction = this.createAction(SetCursorAction);

      cursorAction.toDefault();
    }
  }

  onEveryLongPressActivate() {
    this.longPress = true;
  }

  onEveryPointerUp() {
    this.longPress = false;
  }

  onPointerUp(event, manager) {
    const { isDragging } = manager;
    if (!isDragging) {
      const selection = this.createAction(SetSelectionAction);
      const selectedPathIds = this.getSelectedPathIds();

      // find what's being selected over
      let selected = this.hitTestPaths(event.center) || [];

      // special rules
      if (!selected.length && !this.longPress) {
        this.refreshMenuState({ reason: 'void-click' });
        return true;
      }

      // if multi-select, append to the selection
      if (this.shouldAppendToSelection(event) && selectedPathIds.length) {
        // find the next item to
        const remaining = selected.filter(
          (item) => !selectedPathIds.find((path) => path.pathId === item.pathId)
        );

        // if there's anything to add on
        if (remaining.length) {
          const updated = [...selectedPathIds, remaining[0]];
          selection.set(updated);
        }
        // check if we need to deselect a path
        else if (selected.length) {
          const [remove] = selected;
          const updated = selectedPathIds.filter(
            (path) => path.pathId !== remove.pathId
          );
          selection.set(updated);
        }
      } else {
        // nothing was even selected
        if (!selected.length && !this.longPress) {
          return selection.clear();
        }

        // find a new target
        let target;

        // cycle the selection
        if (selectedPathIds.length) {
          const current = selectedPathIds[selectedPathIds.length - 1];
          const index = selected.findIndex(
            (item) => item.pathId === current.pathId
          );

          // cycle to the next in the selection
          target = selected[(index + 1) % selected.length];
        } else {
          [target] = selected;
        }

        // replace the target
        selection.set([target]);
      }

      // always try and refresh
      const { x, y } = event.center;
      const { isTouch } = event;

      // WREQ-1736
      // When a mobile/touch device user taps on a path to select/deselect it, we
      // want to force clear the hover state as we shouldn't be showing a hover state
      // in the mobile view.
      if (event.pointerType === 'touch') {
        hoverStateHelper.clear();
        return;
      }
      setTimeout(() => this.refreshHoverState({ x, y }, undefined, isTouch));
    } else {
      manager.isDragging = false;
    }
  }
}

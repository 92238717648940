import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// actions
import ToggleTextEditorModeAction from '@/Actions/ToggleTextEditorMode';
import ToggleShapeCreatorModeAction from '@/Actions/ToggleShapeCreatorMode';

// selectors
import {
  toggleIconSearch,
  toggleFileImport,
  selectIsMobile,
  selectIsShowingIconSearch,
  selectIsShowingShapeCreator,
  selectIsShowingTextInsert,
  selectIsShowingFileImport,
  selectFeatures,
} from '@/Redux/Slices/UISlice';
import { selectFeatureFlags } from '@/Redux/Slices/FeatureFlagsSlice';

// components
import CanvasActionMenu from '../CanvasActionMenu';
import { entitlements } from '@/Helpers/Entitlements';
import UIFeatureAction from '@/Actions/UIFeature';
import { selectViewport } from '@/Redux/Slices/ViewportSlice';

type Props = {
  edge: 'left' | 'right';
  disableAll: boolean;
};

// primary canvas menu
export default function DefaultActionMenu(props: Props) {
  const dispatch = useDispatch();

  // selectors
  const isShowingShapeCreator = useSelector(selectIsShowingShapeCreator);
  const isShowingIconSearch = useSelector(selectIsShowingIconSearch);
  const isShowingTextInsert = useSelector(selectIsShowingTextInsert);
  const isShowingFileImport = useSelector(selectIsShowingFileImport);

  const isMobile = useSelector(selectIsMobile);
  const viewport = useSelector(selectViewport);

  // actions
  const textEditorModeAction = useAction(ToggleTextEditorModeAction);
  const uiFeature = useAction(UIFeatureAction);
  const shapeCreatorAction = useAction(ToggleShapeCreatorModeAction);
  const hasImport = selectFeatures(entitlements.IMPORT) || false;

  const featureFlags = useSelector(selectFeatureFlags);

  function showImport() {
    if (hasImport) {
      return true;
    }
    if (!hasImport && isMobile) {
      return true;
    }
    return false;
  }

  // create each action
  const actions = [
    {
      id: 'search',
      icon: 'canvas-action-search',
      label: 'Find Art',
      enabled: selectFeatures(entitlements.FIND_ART_LITE),
      active: isShowingIconSearch,
      toggle: toggleIconSearch,
      i18nKey: 'find-art',
    },
    {
      id: 'draw',
      icon: 'draw',
      label: 'Draw',
      enabled: !!featureFlags['studio-polyline-feature'],
      active: true,
      toggle: () => {
        uiFeature.toggleInsertPoint(true, {
          origin: viewport.position,
          type: isMobile ? 'mobile' : 'default',
        });
      },
      i18nKey: 'draw',
    },
    {
      id: 'shapes',
      icon: 'canvas-action-shapes',
      label: 'Make Shape',
      enabled: selectFeatures(entitlements.SHAPE_SHIFTER),
      active: isShowingShapeCreator,
      dispatch: false,
      toggle: shapeCreatorAction.activate,
      i18nKey: 'make-shape',
    },
    {
      id: 'text',
      icon: 'canvas-action-text',
      label: 'Add Text',
      enabled: selectFeatures(entitlements.TEXT_LITE),
      active: isShowingTextInsert,
      dispatch: false,
      toggle: textEditorModeAction.insert,
      i18nKey: 'add-text',
    },
    {
      id: 'upload',
      icon: 'import-file',
      label: 'File Import',
      enabled: showImport(),
      active: isShowingFileImport,
      toggle: toggleFileImport,
      i18nKey: 'file-import',
    },
  ];

  // activates an action
  function onSelectAction(target: (typeof actions)[number]) {
    // the action will manage the dispatch
    if (target.dispatch === false) {
      target.toggle();
    }
    // normal toggle
    else {
      dispatch(target.toggle(true));
    }
  }

  return (
    <CanvasActionMenu
      onSelectAction={onSelectAction}
      className='default-action-menu'
      edge={props.edge}
      actions={actions}
      expandable='mobile'
      disableAll={props.disableAll}
      dataCy='design-mode-actions'
    />
  );
}

import { Snapshot } from '@/Sync/SyncConstants';
import { CANVAS_DATA_VERSION } from '../defaults';
import { migrations, MigrationError } from './migrations/migrationHelper';
import Ajv, { ErrorObject } from 'ajv';
import { SyncError } from '@/Sync/SyncError';
import { JsonPointer } from 'json-ptr';
import * as Sentry from '@sentry/react';

const ajv = new Ajv({
  useDefaults: true,
  strict: 'log',
  removeAdditional: true,
});

class MigrateCanvas {
  static upOrDown(fromVersion: string, toVersion: string) {
    const fromNumbers = fromVersion.split('.').map((el) => Number(el));
    const toNumbers = toVersion.split('.').map((el) => Number(el));
    for (let i = 0; i < fromNumbers.length; i++) {
      if (fromNumbers[i] < toNumbers[i]) {
        return 'up';
      }
      if (fromNumbers[i] > toNumbers[i]) {
        return 'down';
      }
    }
    return 'same';
  }

  static findMigration(direction: 'up' | 'down', fromVersion: string) {
    return migrations.find(
      (m) => m[direction === 'up' ? 'from' : 'to'] === fromVersion
    );
  }

  static migrate(schema: Snapshot, toVersion: string): any {
    const fromVersion = canvasVersionSelector(schema) ?? '0.0';
    const direction = this.upOrDown(fromVersion, toVersion);
    if (direction === 'same') {
      return schema;
    }

    const currentMigration = this.findMigration(direction, fromVersion);
    if (currentMigration === undefined) {
      throw new MigrationError(fromVersion, toVersion);
    }
    const newSchema = currentMigration[direction](schema);
    return this.migrate(newSchema, toVersion);
  }
}

export const canvasVersionSelector = (state: Snapshot) => {
  return state?.canvas?.version || (state as any)?.version;
};

export const canvasRequiresMigration = (incomingSnapshot: Snapshot) => {
  const toVersion = CANVAS_DATA_VERSION;
  const fromVersion = canvasVersionSelector(incomingSnapshot);
  if (incomingSnapshot !== undefined && toVersion !== fromVersion) {
    return true;
  }
  return false;
};

const getSchemaDefaultValue = (path: string, schema: any) => {
  const numberRegex = new RegExp(/^\d+$/);
  const brokenUpPath = JsonPointer.decode(path);
  const propsOnly = brokenUpPath.filter((p) => !numberRegex.test(p.toString())); // remove any part of paths that point to an index in an array

  const jsonSchema = propsOnly.reduce((previousVal, currentValue) => {
    const value =
      previousVal.type === 'array'
        ? previousVal.items.properties[currentValue]
        : previousVal.properties[currentValue];
    return value;
  }, schema);

  // get all of the path leading up to the last property we search for (including indicies)
  const newPath = brokenUpPath.slice(
    0,
    brokenUpPath.lastIndexOf(propsOnly[propsOnly.length - 1]) + 1
  );

  return { defaultValue: jsonSchema.default, newPath: `/${newPath.join('/')}` };
};

const attemptFix = (
  errors: ErrorObject<string, Record<string, any>, unknown>[],
  schema: any,
  migratedCanvas: any
) => {
  const newMigratedCanvas = JSON.parse(JSON.stringify(migratedCanvas));
  for (const error of errors) {
    const path = JsonPointer.create(error.instancePath);
    if (path.has(newMigratedCanvas)) {
      const { defaultValue, newPath } = getSchemaDefaultValue(
        error.instancePath,
        schema
      );
      JsonPointer.set(newMigratedCanvas, newPath, defaultValue);
    }
  }
  return newMigratedCanvas;
};

const validateSchema = async (
  toVersion: number,
  migratedCanvas: any,
  previousErrors?: ErrorObject<string, Record<string, any>, unknown>[]
) => {
  const json = await import(`./migrations/schema${toVersion}.json`);
  const schema = json.default;
  const validator = ajv.compile(schema);
  const valid = validator(migratedCanvas);
  if (!valid) {
    // WREQ-1750 There are edge cases in the schema migration that we don't currently handle.
    // if we encounter one of those edge cases, we could end up in an infinite loop because this function
    // is recursive.
    // For now we should check to see if we're trying to fix the same errors as the previous iteration.
    // If we are, we should throw an error and halt.
    if (
      previousErrors &&
      JSON.stringify(previousErrors) === JSON.stringify(validator.errors)
    ) {
      const error = new SyncError(
        'bad-workspace',
        'listener',
        'Was unable to fix bad migration',
        undefined,
        {
          validator_errors: validator.errors,
        }
      );
      Sentry.captureException(error);
      throw error;
    }
    if (validator.errors) {
      try {
        const fixedMigratedCanvas = attemptFix(
          validator.errors,
          schema,
          migratedCanvas
        );
        return await validateSchema(
          toVersion,
          fixedMigratedCanvas,
          validator.errors
        );
      } catch (err) {
        console.error('Unable to perform fix for bad migration');
        const error = new SyncError(
          'bad-workspace',
          'listener',
          'Was unable to fix bad migration',
          undefined,
          {
            validator_errors: validator.errors,
          }
        );
        Sentry.captureException(error);
        throw error;
      }
    }

    throw new SyncError(
      'bad-workspace',
      'listener',
      'Workspace may be corrupted',
      undefined,
      {
        validator_errors: validator.errors,
      }
    );
  }
  return migratedCanvas;
};

export const migrateCanvas = async (incomingSnapshot: Snapshot) => {
  const toVersion = CANVAS_DATA_VERSION;
  const migratedCanvas = MigrateCanvas.migrate(incomingSnapshot, toVersion);

  // for migrations 2.1 and onwards, we have schemas to validate against
  const toVersionNum = Number(CANVAS_DATA_VERSION);
  if (!isNaN(toVersionNum) && toVersionNum >= 2.0) {
    const validatedSchema = await validateSchema(toVersionNum, migratedCanvas);
    return validatedSchema;
  }
  return migratedCanvas;
};

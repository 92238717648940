export function cancelEvent(event) {
  if (event.stopPropagation) {
    event.stopPropagation();
  }

  if (event.stopImmediatePropagation) {
    event.stopImmediatePropagation();
  }

  if (event.nativeEvent?.stopImmediatePropagation) {
    event.nativeEvent.stopImmediatePropagation();
  }

  if (event.preventDefault) {
    event.preventDefault();
  }
}

// series of tests to use to be sure we have a touch event
export function isTouchEvent(event) {
  const isMouse = /mouse/i.test(event.event?.pointerType);
  return event.isTouch || !isMouse;
}

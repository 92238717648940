import { mergeAABBArray } from './AABBOps';
import {
  ClipperOps,
  ClipperOpsWorkerProxy,
  ClipperPath,
  ClipperPoint,
} from './ClipperOps';
import {
  transform as ptTransform,
  clone as ptClone,
  comparePoints,
} from './PointOps';
import { pathCloseTolerance } from '@/defaults';
import { toPathArray as simplePolygonToPathArray } from './SimplePolygonOps';
import inside from 'point-in-polygon-hao';
import {
  isSourceSvgObject,
  Path,
} from '@shapertools/sherpa-svg-generator/Path';
import {
  Matrix33,
  initialMatrix33,
} from '@shapertools/sherpa-svg-generator/Matrix33';
import { AABB } from '@shapertools/sherpa-svg-generator/AABB';
import {
  createSvgPathDataFromPath,
  createSvgPathDataFromSimplePolygon,
} from '@shapertools/sherpa-svg-generator/SvgGenerator';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import {
  sanitize as pathSanitize,
  transform as pathTransform,
  clipperPathToShaperPath,
  reversePath,
  shaperPathToScaledClipperPath,
  clone,
  createPathAndTestClosed,
} from './PathOps';
import { CutParams } from '@shapertools/sherpa-svg-generator/CutParams';
import { simplify } from './SimplifyPath';
import { v4 as uuidv4 } from 'uuid';

function getId(basePath: BasePath | BasePath[]): string | string[] {
  if (Array.isArray(basePath)) {
    return basePath.map((bp) => getId(bp)).flat();
  }
  return basePath.id;
}

function sanitize(basePath: BasePath | BasePath[]): Object {
  if (Array.isArray(basePath)) {
    return basePath.map((bp) => sanitize(bp));
  }
  const newPath = pathSanitize(basePath);
  const sanitizedSvg = isSourceSvgObject(basePath.sourceSvg)
    ? {
        svg: basePath.sourceSvg?.svg?.substring(0, 150),
        sourceTransform: basePath.sourceSvg.sourceTransform,
      }
    : basePath.sourceSvg;

  return {
    ...newPath,
    ...(basePath.outerPath !== undefined && {
      outerPath: pathSanitize(basePath.outerPath),
    }),
    ...(basePath.holePaths && {
      holePaths: basePath.holePaths?.map((p) => pathSanitize(p)),
    }),
    sourceSvg: sanitizedSvg,
    cutParams: new CutParams(basePath.cutParams),
  };
}

function getAABB(basePath: BasePath | BasePath[]): AABB {
  if (Array.isArray(basePath)) {
    return mergeAABBArray(basePath.map((bp) => getAABB(bp)));
  }
  if (basePath.outerPath) {
    return basePath.outerPath.AABB;
  }
  return basePath.AABB;
}

function transform(basePath: BasePath, mtx?: Matrix33): BasePath;
// eslint-disable-next-line no-redeclare
function transform(basePath: BasePath[], mtx?: Matrix33): BasePath[];
// eslint-disable-next-line no-redeclare
function transform(
  basePath: BasePath | BasePath[],
  mtx: Matrix33 = initialMatrix33
): BasePath | BasePath[] {
  if (Array.isArray(basePath)) {
    return basePath.map((bp) => transform(bp, mtx)).flat();
  }
  const params = {} as BasePath;
  if (basePath.outerPath) {
    params.outerPath = pathTransform(basePath.outerPath, mtx);
    params.AABB = basePath.outerPath.AABB;
  }
  if (basePath.holePaths) {
    params.holePaths = basePath.holePaths.map((hp) => pathTransform(hp, mtx));
  }
  if (basePath.points) {
    params.points = basePath.points.map((p) => ptTransform(p, mtx));
    params.AABB = new AABB({ points: params.points });
  }

  return new BasePath({
    ...basePath,
    ...params,
    closed: basePath.closed,
  });
}

function signedArea(path: Path) {
  if (path.closed === false) {
    return 0;
  }
  //create segments for closed paths
  return (
    path.points
      .map((pt, idx, ptArr) => {
        let nextPt = idx < ptArr.length - 1 ? ptArr[idx + 1] : ptArr[0];
        return { p0: pt, p1: nextPt };
      })
      //then compute accumulated signed area
      .reduce(
        (acc, seg) => acc + (seg.p0.x * seg.p1.y - seg.p1.x * seg.p0.y),
        0
      )
  );
}

function postProcessTransform(
  basePath: BasePath | BasePath[],
  mtx: Matrix33
): BasePath | BasePath[] {
  if (Array.isArray(basePath)) {
    return basePath.map((bp: BasePath) => postProcessTransform(bp, mtx)).flat();
  }

  const originalId = basePath.id;
  const transformed = transform(basePath, mtx);
  return new BasePath({
    ...basePath,
    ...transformed,
    id: originalId,
  });
}

async function offsetAsync(
  basePath: BasePath | BasePath[],
  offsetValue = 0,
  roundCorners = false,
  offsetOnline = false
): Promise<BasePath[]> {
  if (Array.isArray(basePath)) {
    return (
      await Promise.all(
        basePath.map((bp: BasePath) =>
          offsetAsync(bp, offsetValue, roundCorners, offsetOnline)
        )
      )
    ).flat();
  }

  if (basePath.outerPath) {
    if (basePath.outerPath.closed || !offsetOnline) {
      // TODO: simplifying these paths with `simplifyClipperPolygon` causes
      // inside holes/paths to be simplified out of path. We should look
      // more into fixing this in the future
      const clipperPaths = shaperBasePathToScaledClipperPaths(
        basePath
      ) as ClipperPath[];
      const offsetSimplePolygons = (
        await ClipperOpsWorkerProxy.offsetClipperPolygons(
          clipperPaths,
          offsetValue,
          roundCorners
        )
      ).map(
        (
          clipperPoly: ReturnType<
            typeof ClipperOps.offsetClipperPolygons
          >[number]
        ) =>
          clipperBasePathToShaperPath(
            clipperPoly.outerClipperPath,
            clipperPoly.holeClipperPaths
          )
      );
      return offsetSimplePolygons;
    }
    return Array.isArray(basePath) ? basePath : [basePath];
  }
  if (basePath.closed || !offsetOnline) {
    const clipperPath = shaperBasePathToScaledClipperPaths(basePath)[0];
    const pathToUse = await (async () => {
      if (!basePath.closed) {
        return clipperPath;
      }
      const [simplified] = await ClipperOpsWorkerProxy.simplifyClipperPolygon(
        clipperPath
      );
      return simplified ?? clipperPath;
    })();
    const { closedOffsetPaths, openOffsetPaths } =
      await ClipperOpsWorkerProxy.offsetClipperPath(
        pathToUse.points,
        pathToUse.closed,
        offsetValue,
        roundCorners
      );

    //Offset can create multiple paths from a single path, so need a PathArray)
    const offsetPaths = closedOffsetPaths.map((op: ClipperPoint[]) =>
      clipperBasePathToShaperPath({ points: op, closed: true })
    );
    offsetPaths.push(
      ...openOffsetPaths.map((op: ClipperPoint[]) =>
        clipperBasePathToShaperPath({ points: op, closed: false })
      )
    );
    return offsetPaths;
  }
  return Array.isArray(basePath) ? basePath : [basePath];
}

function offsetSync(
  basePath: BasePath | BasePath[],
  offsetValue = 0,
  roundCorners = false,
  offsetOnline = false
): BasePath[] {
  if (Array.isArray(basePath)) {
    return basePath
      .map((bp: BasePath) =>
        offsetSync(bp, offsetValue, roundCorners, offsetOnline)
      )
      .flat();
  }

  if (basePath.outerPath) {
    if (basePath.outerPath.closed || !offsetOnline) {
      // TODO: simplifying these paths with `simplifyClipperPolygon` causes
      // inside holes/paths to be simplified out of path. We should look
      // more into fixing this in the future
      const clipperPaths = shaperBasePathToScaledClipperPaths(
        basePath
      ) as ClipperPath[];
      const offsetSimplePolygons = ClipperOps.offsetClipperPolygons(
        clipperPaths,
        offsetValue,
        roundCorners
      ).map((clipperPoly) =>
        clipperBasePathToShaperPath(
          clipperPoly.outerClipperPath,
          clipperPoly.holeClipperPaths
        )
      );
      return offsetSimplePolygons;
    }
    return Array.isArray(basePath) ? basePath : [basePath];
  }

  if (basePath.closed || !offsetOnline) {
    const clipperPath = shaperBasePathToScaledClipperPaths(basePath)[0];
    const pathToUse = (() => {
      if (!basePath.closed) {
        return clipperPath;
      }
      const simplifiedPolygons = ClipperOps.simplifyClipperPolygon(clipperPath);
      if (simplifiedPolygons.length > 1) {
        return simplifiedPolygons;
      }
      return simplifiedPolygons[0] ?? clipperPath;
    })();
    if (Array.isArray(pathToUse)) {
      const [outerPath] = pathToUse.splice(0, 1);
      return offsetSync(
        new BasePath({
          ...basePath,
          outerPath: clipperPathToShaperPath(outerPath),
          holePaths: [...pathToUse.map((p) => clipperPathToShaperPath(p))],
          points: undefined,
        })
      );
    }

    const { closedOffsetPaths, openOffsetPaths } = ClipperOps.offsetClipperPath(
      pathToUse.points,
      pathToUse.closed,
      offsetValue,
      roundCorners
    );

    const offsetPaths = closedOffsetPaths.map((op) =>
      clipperPathToShaperPath({ points: op, closed: true })
    );
    offsetPaths.push(
      ...openOffsetPaths.map((op) =>
        clipperPathToShaperPath({ points: op, closed: false })
      )
    );

    return offsetPaths.map((p) => new BasePath({ ...p }));
  }

  return Array.isArray(basePath) ? basePath : [basePath];
}

function generateSvgPathData(
  basePath: BasePath,
  useSourceSvg: boolean = false
): string | string[] {
  if (Array.isArray(basePath)) {
    return basePath
      .map((bp: BasePath) => generateSvgPathData(bp, useSourceSvg))
      .flat();
  }

  if (basePath.outerPath) {
    return createSvgPathDataFromSimplePolygon(basePath, useSourceSvg); //NB returns array of paths
  }
  return createSvgPathDataFromPath(basePath, useSourceSvg);
}

function clonePath(basePath: BasePath | BasePath[]): BasePath | BasePath[] {
  if (Array.isArray(basePath)) {
    return basePath.map((bp: BasePath) => clonePath(bp)).flat();
  }
  const params = {} as BasePath;
  if (basePath.outerPath) {
    params.outerPath = clone(basePath.outerPath);
    params.AABB = basePath.outerPath.AABB;
  }
  if (basePath.holePaths) {
    params.holePaths = basePath.holePaths.map((hp) => clone(hp));
  }
  if (basePath.points) {
    params.points = basePath.points.map((b) => ptClone(b));
    params.AABB = new AABB({ points: params.points });
  }
  if (basePath.cutParams) {
    params.cutParams = new CutParams({ ...basePath.cutParams });
  }

  return new BasePath({
    ...basePath,
    ...params,
    closed: basePath.closed,
    id: uuidv4(),
  });
}

function simplifyPath(basePath: BasePath, tolerance: number) {}

function simplifyClipperPath(basePath: BasePath) {}

function shaperBasePathToScaledClipperPaths(basePath: BasePath): ClipperPath[] {
  if (basePath.outerPath) {
    const clipperArr = [shaperPathToScaledClipperPath(basePath.outerPath)];

    const holePaths = basePath.holePaths
      ?.filter((hp) => hp.points.length > 0)
      .map((hp) => shaperPathToScaledClipperPath(hp));
    if (holePaths) {
      clipperArr.push(...holePaths);
    }

    return clipperArr;
  }

  return [shaperPathToScaledClipperPath(basePath)];
}

function clipperBasePathToShaperPath(
  clipperPath: ClipperPath,
  clipperPaths?: ClipperPath[]
) {
  const path = clipperPathToShaperPath(clipperPath);
  if (clipperPaths) {
    const holePaths = clipperPaths.map((cp) => clipperPathToShaperPath(cp));
    return new BasePath({
      outerPath: path,
      holePaths,
    });
  }

  return new BasePath({
    ...path,
  });
}

function setPathsWindingOrder(basePath: BasePath) {
  let { outerPath, holePaths } = basePath;
  if (outerPath) {
    if (signedArea(outerPath) < 0) {
      outerPath = reversePath(outerPath);
    }
  }

  if (holePaths) {
    holePaths.forEach((hp, idx, hpArr) => {
      if (signedArea(hp) > 0) {
        hpArr[idx] = reversePath(hp);
      }
    });
  }

  return basePath;
}

function closePath(basePath: BasePath | BasePath[]): BasePath | BasePath[] {
  if (Array.isArray(basePath)) {
    return basePath.map((bp: BasePath) => closePath(bp)).flat();
  }

  if (basePath.closed) {
    return clonePath(basePath);
  }

  const { points } = basePath;
  if (
    points.length >= 4 &&
    comparePoints(points[0], points[points.length - 1], pathCloseTolerance)
  ) {
    //Clone points
    return clonePath({
      ...basePath,
      closed: true,
      points: points.slice(0, -1),
    });
  }
  return clonePath({ ...basePath, points: points });
}

function updateSvgSourceTransform(
  basePath: BasePath,
  sourceTransform: Matrix33
): BasePath;
// eslint-disable-next-line no-redeclare
function updateSvgSourceTransform(
  basePath: BasePath[],
  sourceTransform: Matrix33
): BasePath[];
// eslint-disable-next-line no-redeclare
function updateSvgSourceTransform(
  basePath: BasePath | BasePath[],
  sourceTransform: Matrix33
): BasePath | BasePath[] {
  if (Array.isArray(basePath)) {
    return basePath
      .map((bp: BasePath) => updateSvgSourceTransform(bp, sourceTransform))
      .flat();
  }

  if (basePath.sourceSvg === undefined) {
    throw new Error('cutPath does not have sourceSvg');
  }
  if (typeof basePath.sourceSvg == 'object') {
    basePath.sourceSvg.sourceTransform = sourceTransform;
  }
  return basePath;
}

function toPathArray(basePath: BasePath | BasePath[]): BasePath[] {
  if (Array.isArray(basePath)) {
    return basePath.map((bp) => toPathArray(bp)).flat();
  }

  if (basePath.outerPath) {
    return simplePolygonToPathArray(basePath);
  }
  return [basePath];
}

function pointsInsidePath(path: BasePath, points: Point[]) {
  let pathArray: number[][];
  if (path.pathArray !== undefined) {
    pathArray = path.pathArray;
  } else {
    pathArray = path.points.map((p) => [p.x, p.y]);
    pathArray.push(pathArray[0].slice());
    path.pathArray = pathArray;
  }

  return points.some((p) => {
    const result = inside([p.x, p.y], [pathArray]);
    return result === true || result === 0;
  });
}

function joinPaths(pathA: BasePath, pathB: BasePath, cleanTolerance = 0.007) {
  //Paths better have the same cutParams before joining.
  const { cutParams, sourceSvg: sourceSvgA } = pathA;
  const { sourceSvg: sourceSvgB } = pathB;

  const joinedPoints = [...pathA.points, ...pathB.points].map(
    (p) => new Point(p.x, p.y)
  );

  //Remove collinear and doubled-back points by simplifying
  const cleanedPoints = simplify(joinedPoints, cleanTolerance);

  const newPath = createPathAndTestClosed(cleanedPoints);
  const sourceSvg =
    sourceSvgA !== undefined &&
    sourceSvgB !== undefined &&
    typeof sourceSvgA === 'object' &&
    typeof sourceSvgB === 'object'
      ? { ...sourceSvgA, svg: `${sourceSvgA.svg} ${sourceSvgB.svg}` }
      : undefined;
  return new BasePath({ ...newPath, sourceSvg, cutParams });
}

export {
  getId,
  sanitize,
  getAABB,
  transform,
  signedArea,
  postProcessTransform,
  offsetAsync,
  offsetSync,
  generateSvgPathData,
  clonePath,
  simplifyClipperPath,
  simplifyPath,
  pointsInsidePath,
  toPathArray,
  updateSvgSourceTransform,
  closePath,
  clipperBasePathToShaperPath,
  shaperBasePathToScaledClipperPaths,
  setPathsWindingOrder,
  joinPaths,
};

import raphael from 'raphael';
import { isOverlapping } from '@/Geometry/AABBOps';
import { formattedStrToMM } from '@/Geometry/UnitOps';
import { ViewportState } from '@/Redux/Slices/ViewportSlice';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { Point, transform } from '@shapertools/sherpa-svg-generator/Point';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { IBounds } from '@/@types/shaper-types';

const ON_LINE = 1;
const INSIDE = 2;
const OUTSIDE = 3;
const POCKET = 4;

const SCOPES: Record<string, number> = {
  inside: INSIDE,
  outside: OUTSIDE,
  pocket: POCKET,
};

interface HitDetectionMatch {
  groupId: string;
  pathId: string;
  distance?: number;
  cutPath?: boolean;
  edge?: boolean;
}

interface HitDetectionOptions {
  useCutPath?: boolean;
  extend?: number;
  nspf?: number;
  stopAtFirstMatch?: boolean;
  hitDetectFill?: boolean;
  hitDetectEdge?: boolean;
}

interface NearPointOptions {
  viewport?: ViewportState;
  nspf?: number;
  extend?: number;
}

interface HitTestOptions {
  distance?: number;
  viewport?: ViewportState;
  useCutPath?: boolean;
  nspf?: number;
}

type GeneratedPathData = {
  id: string;
  groupId: string;
  area: number;
  xs: number[];
  ys: number[];
  bounds: IBounds;
  points: Point[];
  distances: number[];
  length: number;
  diameter: number;
  cachedTs: number;
  scope: number;
};

interface PreferredMatch {
  distance?: number;
  x?: number;
  y?: number;
}

interface MinMaxBounds {
  minPoint: Point;
  maxPoint: Point;
}
export default class HitDetection {
  static basePaths: Record<string, GeneratedPathData> = {};
  static cutPaths: Record<string, GeneratedPathData> = {};

  // utility to convert screen coordinates to the workspace canvas
  static convertScreenPointToCanvas(
    x: number,
    y: number,
    viewport: ViewportState
  ) {
    return transform({ x, y } as Point, viewport.screenToCanvasTransform);
  }

  // gets the area for a path
  static getArea(group: SvgGroup, pathId: string, useCutPath = false) {
    const source = HitDetection.getPath(group, pathId, useCutPath);
    return source?.area;
  }

  // gets the point data for the specified path
  static getPath(group: SvgGroup, pathId: string, useCutPath = false) {
    let basePath = this.basePaths[pathId];
    let cutPath = this.cutPaths[pathId];

    // generate the basePath, if needed
    if (!basePath || group.generatedTs > basePath?.cachedTs) {
      const result = generatePathData(group, pathId, false);

      // cache for later
      if (result) {
        basePath = this.basePaths[pathId] = result;
        this.basePaths[pathId] = basePath;
      }
    }

    // generate the cut path, if needed
    if (useCutPath && (!cutPath || group.generatedTs > cutPath?.cachedTs)) {
      const result = generatePathData(group, pathId, false);
      if (result) {
        cutPath = this.cutPaths[pathId] = result;
        this.basePaths[pathId] = basePath;
      }
    }

    // return the correct path
    return useCutPath ? cutPath : basePath;
  }

  // finds paths at a point
  static pathsAtPoint(
    x: number,
    y: number,
    group: SvgGroup,
    viewport: ViewportState,
    {
      extend,
      nspf,
      stopAtFirstMatch,
      hitDetectFill,
      hitDetectEdge,
    }: HitDetectionOptions
  ) {
    const matches: HitDetectionMatch[] = [];

    // don't check if this is outside the bounds
    const bounds = getMinMaxBounds(group.transformedAABB, extend);
    if (!pointInBounds(x, y, bounds)) {
      return matches;
    }

    // no need to continue
    for (const path of group.basePathSet) {
      let keep;
      const basePath = HitDetection.getPath(group, path.id, false);

      // check if inside
      if (
        hitDetectFill !== false &&
        pointInPolygon(x, y, basePath.xs, basePath.ys)
      ) {
        keep = true;
      }

      // check if within proximity
      // hit detect along the path
      if (hitDetectEdge !== false) {
        keep =
          keep ||
          hitTestPath(basePath, x, y, {
            viewport,
            distance: (extend ?? 0) * (nspf ?? 1),
            nspf,
          });
      }

      // this was a good match to keep
      if (keep) {
        matches.push({ groupId: group.id, pathId: path.id });

        // if not needing to go further
        if (stopAtFirstMatch) {
          return matches;
        }
      }
    }

    return matches;
  }

  // checks if a group is within a viewport
  static groupInViewport(group: SvgGroup, viewport: ViewportState) {
    const { canvasViewbox: bounds } = viewport;
    const {
      minPoint: { x: left, y: top },
      maxPoint: { x: right, y: bottom },
    } = group.transformedAABB;
    return !(
      left < bounds.minPoint.x ||
      right > bounds.maxPoint.x ||
      top < bounds.minPoint.y ||
      bottom > bounds.maxPoint.y
    );
  }

  // checks a path is within, or intersects, the viewport
  static lineInViewport(a: Point, b: Point, viewport: ViewportState) {
    const { left, right, top, bottom } = getMinMaxBounds(
      viewport.canvasViewbox
    );

    // if either point is in view, we can just consider if
    if (
      (a.x >= left && a.x <= right && a.y >= top && a.y <= bottom) ||
      (b.x >= left && b.x <= right && b.y >= top && b.y <= bottom)
    ) {
      return true;
    }

    // for lines that might not have points in the view, but extend
    // through the viewport, we need to consider them as well
    return (
      lineIntersectsLine(a.x, a.y, b.x, b.y, left, top, right, top) || // top edge
      lineIntersectsLine(a.x, a.y, b.x, b.y, left, bottom, right, bottom) || // bottom edge
      lineIntersectsLine(a.x, a.y, b.x, b.y, left, top, left, bottom) || // left edge
      lineIntersectsLine(a.x, a.y, b.x, b.y, right, top, right, bottom) // right edge
    );
  }

  // checks for points near the edge of a path
  static pointNearPath(
    group: SvgGroup,
    pathId: string,
    point: Point,
    { viewport, nspf, extend }: NearPointOptions = {}
  ) {
    const basePath = HitDetection.getPath(group, pathId, false);
    const cutPath = HitDetection.getPath(group, pathId, true);

    // hit detect along the path
    let hit = hitTestPath(basePath, point.x, point.y, {
      viewport,
      distance: (extend ?? 0) * (nspf ?? 1),
      nspf,
    });

    // checking the cut path distance
    if (!hit && cutPath) {
      hit = hitTestPath(cutPath, point.x, point.y, {
        useCutPath: true,
        nspf,
        viewport,
      });
    }

    // if there's a match, safe the group info
    if (hit) {
      Object.assign(hit as HitDetectionMatch, {
        groupId: group.id,
        pathId: pathId,
      });
    }

    // return the result
    return hit;
  }

  // checks for paths intersecting a bounding box
  static pathIntersectingBounds(
    group: SvgGroup,
    pathId: string,
    bounds: IBounds,
    { useCutPath = false } = {}
  ) {
    // check if the group is just fully contained
    const groupBounds = getMinMaxBounds(group.transformedAABB, 0);
    if (boundsInBounds(groupBounds, bounds)) {
      return [{ groupId: group.id, pathId }];
    }

    // get the paths to check
    const basePath = HitDetection.getPath(group, pathId);
    const cutPath = useCutPath ?? HitDetection.getPath(group, pathId, true);

    // check each path bounds
    const hit = [basePath, cutPath].find((v: GeneratedPathData | boolean) => {
      if (!v) {
        return false;
      }

      // cast to the correct type
      const path = v as GeneratedPathData;

      // if not overlapping the bounding box at all, there's no
      // need to check any further
      if (
        !(
          boundsInBounds(bounds, path.bounds) ||
          boundsOverlapsBounds(bounds, path.bounds) ||
          boundsOverlapsBounds(path.bounds, bounds) ||
          boundsInBounds(path.bounds, bounds)
        )
      ) {
        return false;
      }

      // check all points for an intersection
      // cut the loop into quarters and just check multiple sides at once
      // at the same time. Not sure if this improved much, but might help
      // find matches quicker since it's not starting at one end and going
      // completely to the other side
      const { length: total } = path.points;
      const offset1 = Math.ceil(total / 4);
      const offset2 = offset1 * 2;
      const offset3 = offset1 * 3;
      for (let i = 0; i < offset1; i++) {
        const a = path.points[i % total]; // current
        const b = path.points[(i + 1) % total];
        const c = path.points[(i + offset1) % total]; // 1/4 ahead
        const d = path.points[(i + offset1 + 1) % total];
        const e = path.points[(i + offset2) % total]; // 1/2 ahead
        const f = path.points[(i + offset2 + 1) % total];
        const g = path.points[(i + offset3) % total]; // 3/4 ahead
        const h = path.points[(i + offset3 + 1) % total];

        // check for intersections
        if (
          lineIntersectBounds(a, b, bounds) ||
          lineIntersectBounds(c, d, bounds) ||
          lineIntersectBounds(e, f, bounds) ||
          lineIntersectBounds(g, h, bounds)
        ) {
          return [{ groupId: group.id, pathId }];
        }
      }

      // not hitting at all
      return false;
    });

    return hit;
  }

  // checks if a point is inside of a path polygon
  // TODO: if not closed, should this always return false?
  static pointInsidePath(
    group: SvgGroup,
    pathId: string,
    point: Point,
    { useCutPath = false } = {}
  ) {
    const path = group.basePathSet.find(({ id }) => id === pathId);
    if (!path?.closed) {
      return false;
    }

    // check the x/y positions
    const { xs, ys } = HitDetection.getPath(group, pathId, useCutPath);
    return !!pointInPolygon(point.x, point.y, xs, ys);
  }
}

// generate the path data
function generatePathData(group: SvgGroup, pathId: string, useCutPath = false) {
  const path = group.basePathSet.find(({ id }) => id === pathId) as BasePath;
  const {
    cutParams: { toolDia, cutType },
    closed,
  } = path;
  const diameter = formattedStrToMM(toolDia);
  const scope = SCOPES[cutType] ?? ON_LINE;

  // there's not a great way to get cut paths, so we're going
  // to just parse what's in the document for now. This is an
  // improvement we can make eventually
  let points;
  if (useCutPath) {
    points = getOffsetCutPath(group, pathId);

    // maybe there was no cut path, we can just skip
    if (!points) {
      return null;
    }
  }
  // since we have this path data, we just need to apply transforms
  // to each of the points to get their actual positions
  else {
    points = path.points.map((point: Point) => transform(point, group.TRSMtx));
  }

  // if this is closed, add the first point to the end
  if (closed) {
    points.push(points[0]);
  }

  // lastly, calculate the distances between each point and the full
  // length of the path, which will be used when deciding on how
  // precise edge detection needs to be in some places. The xs/ys are
  // used when determining when a point is within a polygon
  const xs = [];
  const ys = [];
  const distances = [];
  let length = 0;
  for (let i = 0; i < points.length - 1; i++) {
    const a = points[i];
    const b = points[i + 1];
    const distance = distanceBetweenPoints(a.x, b.x, a.y, b.y);

    // save
    length += distance;
    distances.push(distance);
    xs.push(a.x);
    ys.push(a.y);
  }

  // create a bounding box of the max range
  // points and add the diameter to it as well
  // this helps reduce the number of tests later
  // by only including paths that roughly contain
  // the point being checked
  const area = calculateArea(points as Point[]);
  const extend = diameter * 2;
  const bounds = {
    left: Math.min(...xs) - extend,
    right: Math.max(...xs) + extend,
    top: Math.min(...ys) - extend,
    bottom: Math.max(...ys) + extend,
  };

  // return the path data
  return {
    id: pathId,
    groupId: group.id,
    area,
    xs,
    ys,
    bounds: bounds as IBounds,
    points: points as Point[],
    distances,
    length,
    diameter,
    cachedTs: group.generatedTs,
    scope,
  };
}

// hit testing along a path line
function hitTestPath(
  path: GeneratedPathData,
  x: number,
  y: number,
  { viewport, distance, useCutPath, nspf }: HitTestOptions
) {
  const { points, distances, diameter, xs, ys, scope } = path;
  const range = (useCutPath ? diameter * 0.5 : distance) ?? 0;

  // if this is a pocket, we can skip edge checks and
  // just mark if it was a hit or not. The distance doesn't matter
  // since any defined distances will replace this result
  if (scope === POCKET && useCutPath) {
    const inside = pointInPolygon(x, y, xs, ys);
    return (
      inside && {
        distance: Number.MAX_SAFE_INTEGER,
        cutPath: true,
        edge: false,
      }
    );
  }

  // determine where this point is
  const point = { x, y };
  const nearest = { distance: Number.MAX_SAFE_INTEGER };

  // before interpolating edges, check if this point even falls within
  // the bounds of this shape
  if (!pointInBounds(x, y, path.bounds)) {
    return;
  }

  // check all points
  for (let i = 0; i < points.length - 1; i++) {
    const dist = distances[i];
    const a = points[i];
    const b = points[i + 1];

    // before checking the entire edge, make sure it's in view
    if (!HitDetection.lineInViewport(a, b, viewport!)) {
      continue;
    }

    // samples required are based on how zoomed in or out we are
    const samples = Math.max(2, dist / range / (nspf ?? 1));
    const t = 1 / samples;

    // check along an edge for points
    lerpEdge(a, b, point as Point, t, range, nearest);
  }

  // best match
  const hit = nearest.distance < range;

  return (
    hit &&
    ({
      distance: nearest.distance,
      cutPath: !!useCutPath,
      edge: !useCutPath,

      // these are assigned later
      groupId: '',
      pathId: '',
    } as HitDetectionMatch)
  );
}

// interpolate two points
function lerp(a: number, b: number, t: number) {
  return a + t * (b - a);
}

// interpolate along an edge
function lerpEdge(
  a: Point,
  b: Point,
  p: Point,
  t: number,
  radius: number,
  ref: PreferredMatch
) {
  // check all along the path
  for (let i = 0; i <= 1; i += t) {
    const x = lerp(a.x, b.x, i);
    const y = lerp(a.y, b.y, i);

    // check if close enough to consider
    const distance = distanceBetweenPoints(x, p.x, y, p.y);
    if (
      distance < radius &&
      distance < (ref.distance ?? Number.MAX_SAFE_INTEGER)
    ) {
      ref.distance = distance;
      ref.x = x;
      ref.y = y;
    }
  }
}

// checks the distance between two points
function distanceBetweenPoints(x1: number, x2: number, y1: number, y2: number) {
  const px = x1 - x2;
  const py = y1 - y2;
  return Math.sqrt(px * px + py * py);
}

// checks if a point is inside of a basic polygon
function pointInPolygon(x: number, y: number, xs: number[], ys: number[]) {
  let j = xs.length - 1;
  let odd = false;

  for (let i = 0; i < xs.length; i++) {
    if (
      ((ys[i] < y && ys[j] >= y) || (ys[j] < y && ys[i] >= y)) &&
      (xs[i] <= x || xs[j] <= x)
    ) {
      // @ts-ignore -- fancy math logic for checking inside outside
      odd ^= xs[i] + ((y - ys[i]) / (ys[j] - ys[i])) * (xs[j] - xs[i]) < x;
    }
    j = i;
  }

  return odd;
}

// TODO: find a better source than using the actual SVG
function getOffsetCutPath(group: SvgGroup, pathId: string) {
  const channel = document.querySelector(
    `#cutPreviewToolWidth-sg-${group.id}-pg-${pathId}`
  );

  // if this doesn't exist, we can just skip
  if (!channel) {
    return null;
  }

  // parse the values
  const d = channel.getAttribute('d');
  return raphael
    .parsePathString(d as string)
    .map(([, x, y]) => ({
      x: (x as number) + group.position.x,
      y: (y as number) + group.position.y,
    }))
    .filter((v) => ![null, undefined, NaN].includes(v.x));
}

// testing if two lines cross
function lineIntersectsLine(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number
) {
  const d = (x2 - x1) * (y4 - y3) - (x4 - x3) * (y2 - y1);
  if (d === 0) {
    return false;
  }

  const l = ((y4 - y3) * (x4 - x1) + (x3 - x4) * (y4 - y1)) / d;
  const g = ((y1 - y2) * (x4 - x1) + (x2 - x1) * (y4 - y1)) / d;
  return l > 0 && l < 1 && g > 0 && g < 1;
}

// bounding box passes through a line
function lineIntersectBounds(
  a: Point,
  b: Point,
  { left, right, top, bottom }: IBounds
) {
  return (
    lineIntersectsLine(a.x, a.y, b.x, b.y, left, top, right, top) ||
    lineIntersectsLine(a.x, a.y, b.x, b.y, left, bottom, right, bottom) ||
    lineIntersectsLine(a.x, a.y, b.x, b.y, left, top, left, bottom) ||
    lineIntersectsLine(a.x, a.y, b.x, b.y, right, top, right, bottom)
  );
}

// bounding box overlaps another bounding box
function boundsOverlapsBounds(a: IBounds, b: IBounds) {
  return (
    isOverlapping(a.left, a.right, b.left, b.right) &&
    isOverlapping(a.top, a.bottom, b.top, b.bottom)
  );
}

// checks if a point is a bounding box
function pointInBounds(
  x: number,
  y: number,
  { top, left, right, bottom }: IBounds
) {
  return !(x < left || x > right || y < top || y > bottom);
}

// checks if bounds a is within bounds b
function boundsInBounds(a: IBounds, b: IBounds) {
  return (
    a.left > b.left && a.right < b.right && a.top > b.top && a.bottom < b.bottom
  );
}

// gets a transformed bounding box for a group
function getMinMaxBounds(source: MinMaxBounds, extend = 0) {
  const {
    minPoint: { x: left, y: top },
    maxPoint: { x: right, y: bottom },
  } = source;

  // extends the bounds out based on edge detection
  return {
    left: left - extend,
    top: top - extend,
    right: right + extend,
    bottom: bottom + extend,
  } as IBounds;
}

// calculates total area
function calculateArea(points: Point[]) {
  let area = 0;
  const { length: total } = points;

  for (let i = 0; i < total; i++) {
    const j = (i + 1) % total;
    area += points[i].x * points[j].y;
    area -= points[j].x * points[i].y;
  }

  return Math.abs(area) / 2;
}

import { transform } from '@/Geometry/AABBOps';
import UIState from '@/UILayer/State/UIState';

import {
  selectSvgGroupSet,
  selectGetGroupById,
} from '@/Redux/Slices/CanvasSlice';
import { PathId, selectSelectedPathIds } from '@/Redux/Slices/SelectionSlice';
import {
  ViewportState,
  selectNonScalingPixelFactor,
  selectViewport,
} from '@/Redux/Slices/ViewportSlice';

import { uniq } from 'lodash';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { AABB } from '@shapertools/sherpa-svg-generator/AABB';
import { AppDispatch } from '@/Redux/store';
import { UseSelector } from './useAction';
import {
  Shape,
  SvgGroup,
  Tool,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import HitDetection from '@/Utility/HitDetectionV2';

type SelectionDetails = {
  id?: string;
  type: PATH_TYPE;
  groupId?: string;
  group?: SvgGroup;
  path?: BasePath;
  pathId?: string;
};

export default class SelectionNetAction {
  dispatch: AppDispatch;
  useSelector: UseSelector;
  groups: SvgGroup[];
  viewportState: ViewportState;
  startingSelection: PathId[];
  x: number;
  y: number;

  constructor(
    dispatch: AppDispatch,
    useSelector: UseSelector,
    originX: number,
    originY: number
  ) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;

    this.groups = useSelector(selectSvgGroupSet);
    this.viewportState = useSelector(selectViewport);
    this.startingSelection = useSelector(selectSelectedPathIds);

    this.x = originX;
    this.y = originY;
  }

  // updates
  update(x: number, y: number) {
    const { groups } = this;
    const { screenToCanvasTransform } = this.viewportState;
    const getGroupById = this.useSelector(selectGetGroupById);
    const nspf = this.useSelector(selectNonScalingPixelFactor);

    //Create AABB corresponding to selection net
    const p0 = new Point(this.x, this.y);
    const p1 = new Point(x, y);

    const netAABBScreenSpace = new AABB({
      minPoint: p0,
      maxPoint: p1,
    });

    //Now transform netAABB to canvas space for easier hit detection
    const netAABBCanvasSpace = transform(
      netAABBScreenSpace,
      screenToCanvasTransform
    );

    // create the selection
    const selection = [] as SelectionDetails[];
    const a = HitDetection.convertScreenPointToCanvas(
      this.x,
      this.y,
      this.viewportState
    );
    const b = HitDetection.convertScreenPointToCanvas(x, y, this.viewportState);
    const extend = 5 * nspf;
    const bounds = {
      left: Math.min(a.x, b.x) - extend,
      top: Math.min(a.y, b.y) - extend,
      right: Math.max(a.x, b.x) + extend,
      bottom: Math.max(a.y, b.y) + extend,
    };

    // check for overlapped layers
    for (const group of groups) {
      for (const path of group.basePathSet) {
        const hit = HitDetection.pathIntersectingBounds(group, path.id, bounds);
        if (hit) {
          selection.push({
            group,
            path,
            pathId: path.id,
            groupId: group.id,
            type: group.type,
          });
        }
      }
    }

    // check if the selection contains a mix of types
    const types = selection.filter(
      (item) => ![Shape.POINT, Shape.SHAPE].includes(item.group!.tool.type)
    );
    const shapeCount = selection.filter(
      (item) => item.group!.tool.type === Shape.SHAPE
    );
    const isMixedSelection = types.length > 0 || shapeCount.length > 1;

    // with the final selection, we need to modify it depending on polyline
    // selections
    // always remove shapes from a selection
    // if only a shape is selected, select all points
    // if a point is selected, and objects/points from other groups are selected, select all points
    [...selection].forEach((selected) => {
      // if this is a shape
      if (selected.group!.tool.type === Shape.SHAPE) {
        let selectAllPoints = isMixedSelection;

        // if not already including all points, check if any
        // point is is already selected
        if (!selectAllPoints) {
          selectAllPoints = !selection.find((item) =>
            (selected.group!.tool as Tool<Shape.SHAPE>).params.points.includes(
              item.groupId!
            )
          );
        }

        // if no other points are selected, then select all
        // of the points in this selection
        if (selectAllPoints) {
          selection.push(
            ...(selected.group!.tool as Tool<Shape.SHAPE>).params.points.map(
              (pointId) => ({
                groupId: pointId,
                pathId: 'none',
                group: getGroupById(pointId),
                type: PATH_TYPE.DESIGN,
              })
            )
          );
        }

        // always remove shapes
        const index = selection.findIndex((item) => item.id === selected.id);
        selection.splice(index, 1);
      }
    });

    // remove duplicates
    const ids: { [key: string]: boolean } = {};
    for (let i = selection.length; i-- > 0; ) {
      const key = `${selection[i].groupId}:${selection[i].pathId}`;

      // has already been added
      if (ids[key]) {
        selection.splice(i, 1);
      }

      ids[key] = true;
    }

    // clean up the selection
    for (const item of selection) {
      delete item.group;
      delete item.path;
    }

    // calculate the size
    UIState.apply((state: any) => {
      const { x: left, y: top } = netAABBCanvasSpace.minPoint;
      const { x: right, y: bottom } = netAABBCanvasSpace.maxPoint;
      state.selectionNet = { left, right, top, bottom };
    });

    // return the final selection which includes any groups
    // that were in the initial selection
    return uniq([...selection, ...this.startingSelection]);
  }

  // end
  resolve() {
    UIState.reset();
  }
}

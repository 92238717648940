import BaseInteraction from './BaseInteraction';
import TranslateViewportAction from '@/Actions/TranslateViewport';
import SetCursorAction from '../../Actions/SetCursor';

import {
  selectIsDesignMode,
  selectIsShowingShapeShifter,
} from '@/Redux/Slices/UISlice';
import { isTouchEvent } from '@/Utility/events';

export default class TranslateViewportInteraction extends BaseInteraction {
  interactionId = 'Translate Viewport';

  onEveryKeyDown({ key }) {
    if (key === ' ') {
      this.forceActivation = true;

      // update the cursor
      const cursor = this.createAction(SetCursorAction);
      cursor.toGrab();
    }
  }

  onEveryKeyUp({ key }) {
    if (key === ' ') {
      this.forceActivation = false;

      // update the cursor
      const cursor = this.createAction(SetCursorAction);
      cursor.toDefault();
    }
  }

  onEveryWindowExit() {
    this.forceActivation = false;

    // update the cursor
    const cursor = this.createAction(SetCursorAction);
    cursor.toDefault();
  }

  onPointerDown() {
    if (this.forceActivation) {
      this.setActive();
      return false;
    }
  }

  onPointerUp() {
    this.release();
  }

  shouldBeginTranslateViewport(event) {
    if (this.forceActivation) {
      return true;
    }

    const isTouch = isTouchEvent(event);
    const isDesignMode = this.useSelector(selectIsDesignMode);
    const overSelection = this.hitTestSelectionBox(event.center);
    const groups = this.getGroupsAt({
      x: event.center.x,
      y: event.center.y,
      ignoreReferencePaths: true,
    });

    // allow selecting and moving groups
    if (isDesignMode && groups.length && !isTouch) {
      return false;
    }

    // if nothing has been selected, touch interactions will
    // simply pan the view
    if (!overSelection && isTouch) {
      return true;
    }

    if (overSelection) {
      return false;
    }

    // by default, translate
    return true;
  }

  onPointerMoveStart(event) {
    const isShapeShifterMode = this.useSelector(selectIsShowingShapeShifter);
    if (isShapeShifterMode) {
      if (event.event?.srcElement?.tagName?.includes('path')) {
        return;
      }
    }

    // if this is over a selection box, then we can
    // assume it's a transform attempt for the groups
    if (!this.shouldBeginTranslateViewport(event)) {
      return;
    }

    // activate translation
    this.setActive();
    this.action = this.createAction(TranslateViewportAction);
  }

  onActivePointerMove(event) {
    const { deltaX, deltaY } = event;

    // WREQ-1736
    // In InteractionMode.ts we are using use-gesture to register a drag handler
    // in that handler we are overloading the fired events to add these deltaX and deltaY properties
    // this causes issues when we register any other listeners for pointerMove as events fired by anything except
    // use-gesture's drag handler will not have these properties.
    // the simple solution to only listen to use-gesture's drag events is to skip events that don't have these properties.

    // TODO simplify our useage of mouse events and pointer events, as we have a lot of implicit assumptions
    // in our event handlers that will cause issues.
    if (deltaX && deltaY) {
      this.action?.panBy(-deltaX, -deltaY);
      return false;
    }
  }

  onActivePointerMoveEnd() {
    this.action?.resolve();

    // allow other interactions
    this.release();

    // update the cursor
    const cursor = this.createAction(SetCursorAction);
    cursor.toDefault();

    return false;
  }
}

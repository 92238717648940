import { getAABBSize, transform as AABBTransform } from './AABBOps';
import {
  createScaleMtx,
  createTranslateMtx,
  createRotationMtx,
  clone as matrixClone,
  getSVGTransformParams,
  invert,
  createStretchMtx,
} from '@shapertools/sherpa-svg-generator/Matrix33';
import { transform as pathTransform } from './PathOps';
import { defaultSvgGroupSize, EPS_TOLERANCE } from '@/defaults';
import {
  getAABB,
  transform as basePathTransform,
  clonePath,
  getId,
  sanitize,
} from './BasePathOps';
import { add, clone as ptClone, scalarMul } from './PointOps';
import RoundPath from './RoundPath';
import { SvgOps } from './SvgParser';
import { closePaths, joinPaths, removeDuplicatePaths } from './PathRepair';
import { difference } from './Helpers';
import {
  CutParams,
  CutType,
} from '@shapertools/sherpa-svg-generator/CutParams';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import {
  Matrix33,
  multiplyMatrix33,
} from '@shapertools/sherpa-svg-generator/Matrix33';
import { AABB } from '@shapertools/sherpa-svg-generator/AABB';
import {
  isSvgGroupForShape,
  isToolForShape,
  Shape,
  SvgGroup,
  Tool,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import {
  isSourceSvgObject,
  PATH_TYPE,
} from '@shapertools/sherpa-svg-generator/Path';
import { Anchor } from '@/@types/shaper-types';
import { createScaledBasePathSvg } from './SvgCache/BasePathCache';
import {
  createAlignedNGonForCircle,
  createSvgForTool,
} from '@/Helpers/ShapeCreator';
import { unitToMMNum } from './UnitOps';
import { getPathPointsFromUSVGPathCmds, getPathScale } from './Tessellation';

const CLOSE_PATHS = true;
const JOIN_PATHS = true;
const REMOVE_DUPLICATE_PATHS = true; //Expensive, may need to disable until better profiling and performance

type CreateSVGGroupFromSvgParams = {
  useGroupSize: boolean;
  position?: Point;
  svgStr: string;
  tool?: Tool;
  origin?: Point;
  repair?: boolean;
  originWorkpiece?: boolean;
};

// Wrapper for createSVGGroupAndImportPositionFromSvg function to keep old API and let new function provide importPosition where needed.

function createSvgGroupFromSvg(params: CreateSVGGroupFromSvgParams) {
  //
  const { svgGroup } = createSVGGroupAndImportPositionFromSvg(params);

  return svgGroup;
}

function createSVGGroupAndImportPositionFromSvg(
  params: CreateSVGGroupFromSvgParams
) {
  const {
    useGroupSize,
    svgStr,
    repair,
    tool,
    originWorkpiece = false,
  } = params;
  const { cutPaths, displaySize, importPosition } =
    createBasePathsAndDisplaySizeFromSvg(
      useGroupSize,
      svgStr,
      tool,
      undefined,
      originWorkpiece
    );
  const processedPaths = repair ? repairPaths(cutPaths) : cutPaths;
  const svgGroup = applyPostProcess(
    new SvgGroup({
      basePathSet: processedPaths,
      ...params,
      ...(displaySize && { displaySize }),
    })
  );
  return { svgGroup, importPosition };
}

function getSvgGroupScale(baseAABB: AABB, displaySize: Point) {
  const baseSize = getAABBSize(baseAABB);
  return new Point(displaySize.x / baseSize.x, displaySize.y / baseSize.y);
}

export enum SvgGroupUpdateKey {
  /* eslint-disable no-unused-vars */
  DisplaySize = 'displaySize',
  DisplaySizeRelativePosition = 'displaySize:relative_position',
  RelativeStretchRelativePosition = 'relative_stretch:relative_position',
  RotationRelativePosition = 'rotation:relative_position',
  RelativePosition = 'relative_position',
  AbsolutePosition = 'absolute_position',
  ForceOpenPaths = 'forceOpenPaths',
  ToolSvg = 'toolSvg',
  /* eslint-disable-next-line @typescript-eslint/no-shadow */
  Anchor = 'anchor',
  PathType = 'pathtype',
  /* eslint-enable no-unused-vars */
}
type SvgGroupKeyValues = {
  [key in keyof SvgGroup]-?: { key: key; value: SvgGroup[key] };
};
export type SvgGroupUpdate =
  | {
      key: SvgGroupUpdateKey.DisplaySize;
      value: Point;
    }
  | {
      key: SvgGroupUpdateKey.DisplaySizeRelativePosition;
      value: any;
    }
  | {
      key: SvgGroupUpdateKey.RelativeStretchRelativePosition;
      value: {
        deltaPos: Point;
        stretchMtx: Matrix33;
      };
    }
  | {
      key: SvgGroupUpdateKey.RotationRelativePosition;
      value: {
        deltaPosition: Point;
      } & (
        | {
            deltaRotation: number;
            setRotation: undefined;
          }
        | {
            deltaRotation: undefined;
            setRotation: number;
          }
      );
    }
  | {
      key: SvgGroupUpdateKey.RelativePosition;
      value: Point;
    }
  | {
      key: SvgGroupUpdateKey.AbsolutePosition;
      value: Point;
    }
  | {
      key: SvgGroupUpdateKey.ToolSvg;
      value: {
        tool: Tool;
        rawSVG: string;
        deltaPos?: Point;
        setPos?: Point;
        setPosition?: Point;
        constantDim?: 'width';
        stretchMtx?: Matrix33;
      };
    }
  | {
      key: SvgGroupUpdateKey.Anchor;
      value: Anchor;
    }
  | {
      key: SvgGroupUpdateKey.PathType;
      value: PATH_TYPE;
    }
  | SvgGroupKeyValues[Exclude<keyof SvgGroup, 'displaySize' | 'anchor'>];

function updateKeyAndValue(
  svgGroup: SvgGroup,
  update: SvgGroupUpdate,
  hasTessellationFeatureFlag: boolean // TODO: can remove once tessellation feature is complete
) {
  const { key, value } = update;

  let oldDims = new Point();

  switch (key) {
    case SvgGroupUpdateKey.DisplaySize:
      svgGroup.displaySize = value;
      rescaleStretchMtx(svgGroup);
      break;
    case SvgGroupUpdateKey.DisplaySizeRelativePosition:
      throw new Error(`Deprecated key ${key}`);
    case SvgGroupUpdateKey.RelativeStretchRelativePosition:
      stretchAndPosition(
        svgGroup,
        value.deltaPos,
        value.stretchMtx,
        hasTessellationFeatureFlag // TODO: can remove once tessellation feature is complete
      );
      break;
    case SvgGroupUpdateKey.RotationRelativePosition:
      rotateAndTranslate(
        svgGroup,
        value.deltaPosition,
        value.deltaRotation,
        value.setRotation
      );
      break;
    case SvgGroupUpdateKey.RelativePosition:
      relativeTranslate(svgGroup, value);
      break;
    case SvgGroupUpdateKey.AbsolutePosition:
      translate(svgGroup, value);
      break;
    case SvgGroupUpdateKey.ToolSvg:
      svgGroup.tool = value.tool;

      if ('deltaPos' in svgGroup.tool.params) {
        delete svgGroup.tool.params.deltaPos;
      }

      if (value.constantDim !== undefined) {
        oldDims = getAABBSize(svgGroup.transformedAABB);
      }
      const { cutPaths, displaySize } = createBasePathsAndDisplaySizeFromSvg(
        true,
        value.rawSVG,
        svgGroup.tool
      );

      //Update cutParams when toolSvg changes
      //This needs to be done when changing polygon sides, resizing rounded rectangles, and updating text
      //NOTE - this assumes that all cutParams are the same, because we have no way of associating old pathIds with new pathIds, especially where the number of paths change after the update, which is common with text updates
      const oldCutParams =
        svgGroup.basePathSet[0]?.cutParams ||
        new CutParams({ cutType: CutType.OUTSIDE });

      svgGroup.basePathSet = cutPaths;
      svgGroup.displaySize = displaySize ?? new Point();

      //Copy last cutParams into new basePaths
      svgGroup.basePathSet.forEach((bp) => {
        if (bp.outerPath) {
          bp.cutParams = new CutParams({
            ...oldCutParams,
            ...(!bp.outerPath.closed ? { cutType: CutType.ONLINE } : {}),
          });
        } else {
          bp.cutParams = new CutParams({
            ...oldCutParams,
            ...(!bp.closed ? { cutType: CutType.ONLINE } : {}),
          });
        }
      });

      if (value.deltaPos !== undefined) {
        svgGroup.position = add(svgGroup.position, value.deltaPos);
      }
      if (value.stretchMtx !== undefined) {
        svgGroup.stretchMtx = multiplyMatrix33(
          value.stretchMtx,
          svgGroup.stretchMtx
        );
      }
      if (value.constantDim !== undefined) {
        //This rescales svgGroup to match prior width or height
        //Used for scaling text groups when font changes.
        updateSvgGroupTransform(svgGroup);
        const newDims = getAABBSize(svgGroup.transformedAABB);
        const ratio =
          value.constantDim === 'width'
            ? oldDims.x / newDims.x
            : oldDims.y / newDims.y;
        const newScaleMtx = createScaleMtx(ratio);
        svgGroup.stretchMtx = multiplyMatrix33(
          newScaleMtx,
          svgGroup.stretchMtx
        );
      }
      break;
    case SvgGroupUpdateKey.Anchor:
      svgGroup.anchor = value;
      break;
    case SvgGroupUpdateKey.PathType:
      svgGroup.type = value;
      svgGroup.basePathSet.forEach((b) => {
        b.type = value;
      });
      break;
    default:
      // @ts-ignore Typescript can't properly typecheck this line, but the types above ensure it works
      svgGroup[key] = value;
  }

  updateTs(svgGroup);
  updateSvgGroupTransform(svgGroup);

  if (key === SvgGroupUpdateKey.ToolSvg) {
    applyPostProcess(svgGroup);
  }
}

function updateCutParams(
  svgGroup: SvgGroup,
  pathId: string,
  newCutParams: CutParams
) {
  const selectedPath = svgGroup.basePathSet.find((bp) => bp.id === pathId);

  if (selectedPath === undefined) {
    throw new Error(`pathId ${pathId} not found`);
  }

  selectedPath.cutParams = newCutParams;

  updateTs(svgGroup);
}

export function createBasePathsAndDisplaySizeFromSvg(
  useGroupSize: boolean = false,
  svgStr: string = '',
  tool?: Tool,
  preferSize?: Point,
  originWorkpiece?: boolean
): {
  cutPaths: BasePath[];
  displaySize: Point | null;
  importPosition: Point | null;
} {
  const forceOpenPaths = tool?.params?.forceOpenPaths;
  const { cutPaths, svgSize, importPosition } = SvgOps.convertSvgStrToPaths(
    svgStr,
    forceOpenPaths,
    originWorkpiece
  ); //paths is array of Path Objects

  const displaySize = (() => {
    //Icons from noun project are imported and scaled to 25mm / 1 in by default
    //SVG and DXF files are imported and scaled to actual dimensions listed in file, if possible
    if (useGroupSize) {
      return svgSize;
    } else if (preferSize) {
      return preferSize;
    }
    //useGroupSize === false, scale svg to default dimensions
    const defaultDim = defaultSvgGroupSize.imperial;
    //Default svgGroupSize is now set in defaults for metric and imperial units.
    //When user unit settings are enabled, we can toggle between these.

    const baseAABB = getAABB(cutPaths as BasePath[]);
    const groupSize = getAABBSize(baseAABB);
    const newDisplaySize = new Point();
    if (groupSize.x > groupSize.y) {
      newDisplaySize.x = defaultDim;
      newDisplaySize.y = (defaultDim * groupSize.y) / groupSize.x;
    } else {
      newDisplaySize.y = defaultDim;
      newDisplaySize.x = (defaultDim * groupSize.x) / groupSize.y;
    }
    return newDisplaySize;
  })();

  return { cutPaths: cutPaths as BasePath[], displaySize, importPosition };
}

function updateSvgGroupTransform(svgGroup: SvgGroup) {
  //Stretch matrix is updated separately, because it depends on whether update is a relative or absolute scaling operation
  const rotateMtx = createRotationMtx(svgGroup.rotation);
  svgGroup.rotateMtx = rotateMtx;
  svgGroup.translateMtx = createTranslateMtx(svgGroup.position);
  svgGroup.TRSMtx = multiplyMatrix33(
    svgGroup.translateMtx,
    rotateMtx,
    svgGroup.stretchMtx
  );

  const TRSPathSet = basePathTransform(svgGroup.basePathSet, svgGroup.TRSMtx);

  svgGroup.transformedAABB = getAABB(TRSPathSet);
  svgGroup.unrotatedAABB = AABBTransform(
    getAABB(basePathTransform(svgGroup.basePathSet, svgGroup.stretchMtx)),
    svgGroup.translateMtx
  );
  return svgGroup;
}

function repairPaths(paths: BasePath[]): BasePath[] {
  const passedPaths = paths.filter((p) => p.outerPath); // paths that have already been shapeshifted
  let processedPaths = paths.filter(
    (p) => !p.outerPath && p.points && p.points.length > 0
  ); // all other paths
  if (CLOSE_PATHS) {
    processedPaths = closePaths(processedPaths) as BasePath[];
  }
  if (JOIN_PATHS) {
    processedPaths = joinPaths(processedPaths) as BasePath[];
  }

  if (REMOVE_DUPLICATE_PATHS) {
    processedPaths = removeDuplicatePaths(processedPaths);
  }

  return [...processedPaths, ...passedPaths];
}

function clone(svgGroup: SvgGroup, newPaths?: BasePath[]): SvgGroup {
  const basePathSet = (() => {
    if (newPaths) {
      return newPaths;
    }
    const basePaths = clonePath(svgGroup.basePathSet);
    if (!Array.isArray(basePaths)) {
      return [basePaths];
    }

    return basePaths;
  })();

  return new SvgGroup({
    useId: svgGroup.useId,
    tool: JSON.parse(JSON.stringify(svgGroup.tool)),
    position: ptClone(svgGroup.position),
    rotation: svgGroup.rotation,
    displaySize: ptClone(svgGroup.displaySize),
    stretchMtx: matrixClone(svgGroup.stretchMtx),
    basePathSet: basePathSet,
    anchor: svgGroup.anchor,
  });
}

function updateTs(svgGroup: SvgGroup) {
  svgGroup.generatedTs = Date.now();
}

function rescaleStretchMtx(svgGroup: SvgGroup) {
  const { stretchMtx, baseAABB, displaySize } = svgGroup;

  // for a shape, revert the scale to the original size and then
  // scale it up based on the new display size
  if (isSvgGroupForShape(svgGroup, Shape.SHAPE)) {
    const tw =
      (svgGroup.transformedAABB.maxPoint.x -
        svgGroup.transformedAABB.minPoint.x) /
      (svgGroup.stretchMtx[0][0] || 1);
    const th =
      (svgGroup.transformedAABB.maxPoint.y -
        svgGroup.transformedAABB.minPoint.y) /
      (svgGroup.stretchMtx[1][1] || 1);

    const sx = displaySize.x / (tw || 1);
    const sy = displaySize.y / (th || 1);

    svgGroup.displaySize = { ...displaySize };
    svgGroup.stretchMtx = createStretchMtx(sx, sy);

    return svgGroup;
  }

  const newScale = getSvgGroupScale(baseAABB, displaySize);

  //newScale resizes group from baseBB to display size. To get new stretch mtx, need to normalize shears to baseBB before scaling matrix to new size

  const oldScaleX = stretchMtx[0][0];
  const oldScaleY = stretchMtx[1][1];

  svgGroup.stretchMtx = [
    [newScale.x, (newScale.x / oldScaleX) * stretchMtx[0][1], 0],
    [(newScale.y / oldScaleY) * stretchMtx[1][0], newScale.y, 0],
    [0, 0, 1],
  ];
  updateTs(svgGroup);
  return svgGroup;
}

function stretchAndPosition(
  svgGroup: SvgGroup,
  deltaPos: Point,
  stretchMtx: Matrix33,
  hasTessellationFeatureFlag: boolean // TODO: can remove once tessellation feature is complete
) {
  svgGroup.position = add(svgGroup.position, deltaPos);

  svgGroup.stretchMtx = multiplyMatrix33(stretchMtx, svgGroup.stretchMtx);

  // TODO: can remove once tessellation feature is complete
  if (!hasTessellationFeatureFlag) {
    if (svgGroup.tool?.type === 'circle' || svgGroup.tool?.type === 'ellipse') {
      const cloned = matrixClone(stretchMtx);
      cloned[0][0] = Math.abs(cloned[0][0]);
      cloned[1][1] = Math.abs(cloned[1][1]);
      return retessellateCircle(svgGroup, cloned);
    }
  }

  return svgGroup;
}

// TODO: can remove once tessellation feature is complete
function retessellateCircle(svgGroup: SvgGroup, stretchMtx: Matrix33) {
  const shearX = stretchMtx[0][1] || svgGroup.stretchMtx[0][1];
  const shearY = stretchMtx[1][0] || svgGroup.stretchMtx[1][0];
  if (Math.abs(shearX) > EPS_TOLERANCE || Math.abs(shearY) > EPS_TOLERANCE) {
    return svgGroup;
  }
  const { tool } = svgGroup;
  const { width, height } = (() => {
    if (isToolForShape(tool, Shape.CIRCLE)) {
      const w = (tool.params.diameter || 1) * stretchMtx[0][0];
      const h = (tool.params.diameter || 1) * stretchMtx[1][1];
      return {
        width: w,
        height: h,
      };
    }
    return {
      width: svgGroup.tool.params.width! * stretchMtx[0][0],
      height: svgGroup.tool.params.height! * stretchMtx[1][1],
    };
  })();
  const { cutParams, id } = svgGroup.basePathSet[0]; //save cut params and id from original circle/ellipse
  const svgStr = (() => {
    if (
      svgGroup.tool.type === Shape.CIRCLE &&
      Math.abs(width - height) <= EPS_TOLERANCE
    ) {
      return createSvgForTool(
        new Tool(Shape.CIRCLE, {
          diameter: width,
          width,
          height: width,
          units: svgGroup.tool?.params.units,
        })
      );
    }

    return createSvgForTool(
      new Tool(Shape.ELLIPSE, {
        units: svgGroup.tool?.params.units,
        width,
        height,
      })
    );
  })();
  const { cutPaths } = createBasePathsAndDisplaySizeFromSvg(true, svgStr.svg);
  const processedPaths = repairPaths(cutPaths);

  svgGroup.basePathSet[0] = {
    ...processedPaths[0],
    cutParams,
    id,
  };

  svgGroup.tool = (() => {
    if (
      svgGroup.tool.type === Shape.CIRCLE &&
      Math.abs(width - height) <= EPS_TOLERANCE
    ) {
      return new Tool(Shape.CIRCLE, {
        diameter: width,
        width,
        height: width,
        units: svgGroup.tool?.params.units,
      });
    }

    return new Tool(Shape.ELLIPSE, {
      units: svgGroup.tool?.params.units,
      width,
      height,
    });
  })();
  svgGroup.stretchMtx = createStretchMtx();
  return svgGroup;
}

function rotateAndTranslate(
  svgGroup: SvgGroup,
  deltaPosition: Point = new Point(),
  deltaRotation: number = 0,
  setRotation?: number
) {
  const originalSvgGroup = JSON.parse(JSON.stringify(svgGroup));
  svgGroup.position = add(svgGroup.position, deltaPosition);

  // shapes and points are not rotated
  if ([Shape.SHAPE, Shape.POINT].includes(svgGroup.tool.type)) {
    // no-op - do not change
    svgGroup.rotation = 0;
    return svgGroup;
  }

  const rotateTo = setRotation as number;
  if (!isNaN(rotateTo)) {
    svgGroup.rotation = rotateTo;
  } else {
    svgGroup.rotation += deltaRotation;
  }

  difference(originalSvgGroup, svgGroup);
  return svgGroup;
}

function relativeTranslate(svgGroup: SvgGroup, deltaPos: Point) {
  svgGroup.position = add(svgGroup.position, deltaPos);

  return svgGroup;
}

function translate(svgGroup: SvgGroup, position: Point) {
  svgGroup.position = new Point(position.x, position.y);

  return svgGroup;
}

//Creates standalone svg of group for import mode
function createStandaloneGroupSvg(
  svgGroup: SvgGroup,
  hasTessellationFeatureFlag: boolean // TODO: can remove once tessellation feature is complete
) {
  const basePathsSvg = svgGroup.basePathSet.map((basePath) => {
    const transformAttr = (() => {
      if (isSourceSvgObject(basePath.sourceSvg)) {
        const transformMtx = basePath.sourceSvg.sourceTransform;

        return [
          {
            name: 'transform',
            value: `${getSVGTransformParams(transformMtx)}`,
          },
        ];
      }
      return [];
    })();
    return createScaledBasePathSvg(
      svgGroup.id,
      getId(basePath) as string,
      basePath,
      hasTessellationFeatureFlag,
      ['import'],
      transformAttr
    );
  });

  return basePathsSvg;
}

function getSanitizedSvgGroup(svgGroup: SvgGroup): Object {
  return {
    ...svgGroup,
    // reduce how many basepaths are showing to help with performance of devtools
    basePathSet: sanitize(svgGroup.basePathSet.slice(0, 25)),
  };
}

/**
 * Function to apply the post process functions on a BasePathSet
 * @param {SvgGroup} svgGroup - the svg group that contains the `basePathSet` that needs the post process functions applied to
 * @returns {SvgGroup} returns the svg group that was passed in
 */
function applyPostProcess(svgGroup: SvgGroup) {
  const { tool } = svgGroup;
  if (tool?.params?.postProcess === 'round') {
    // Automatic path closing has been added back to SvgParser to avoid paths not being closed correctly.
    const roundedPaths = RoundPath.roundPath({
      paths: svgGroup.basePathSet,
      tool,
    });
    svgGroup.basePathSet = roundedPaths;
  }

  return svgGroup;
}

// TODO: can remove once tessellation feature is complete
function getTRSPathSet_deprecating(svgGroup: SvgGroup) {
  const TRSPathSet: BasePath[] = [];
  const { stretchMtx, TRSMtx } = svgGroup;
  let transformMtx = TRSMtx;

  //Our JS Clipper port does not clip circles against corners correctly unless the tessellated circle touches the edges on its faces, not just single points.
  //This is a hacky way to generate tessellated circles with faces aligned at 0 and 90 degrees to match the snapping feature
  //Long-term, we should add a general purpose corner rounding tool so that we don't need shapeshifter for radiusing and/or switch to a webAsssembly version of clipper that hopefully doesn't have this issue

  const { tool } = svgGroup;
  if (
    isToolForShape(tool, Shape.CIRCLE) ||
    isToolForShape(tool, Shape.ELLIPSE)
  ) {
    let diameterMM;
    let stretchMax;
    let invUniformScaleMtx;
    if (isToolForShape(tool, Shape.CIRCLE)) {
      const { diameter, units } = tool.params;
      diameterMM = unitToMMNum(diameter, units);
      stretchMax = Math.max(
        Math.abs(svgGroup.stretchMtx[0][0]),
        Math.abs(svgGroup.stretchMtx[1][1])
      );
      invUniformScaleMtx = createScaleMtx(1 / stretchMax);
    } else {
      const { width, height, units } = tool.params;
      const maxDiameter = Math.max(Math.abs(width), Math.abs(height));
      const xMax = width > height;
      const ellipseScaleMtx = xMax
        ? createScaleMtx(1, height! / width!)
        : createScaleMtx(width! / height!, 1); //Converts a circle of diameter maxDim to an ellipse of width, height

      diameterMM = unitToMMNum(maxDiameter, units!);

      //Even ellipses can be resized, so need to further scale here
      stretchMax = Math.max(
        Math.abs(svgGroup.stretchMtx[0][0]),
        Math.abs(svgGroup.stretchMtx[1][1])
      );

      /*
 					given ellipse w,h, and w > h, and stretch = sx, sy, and smax = max(sx, sy)
 					make circle diameter smax * w, then scale by 1/smax to get unstretched circle, scale by (1, h/w) to change back to ellipse w,h, scale by stretch to get ellipse of w,h stretched by sx,sy
 				*/

      invUniformScaleMtx = multiplyMatrix33(
        ellipseScaleMtx,
        createScaleMtx(1 / stretchMax)
      );
    }

    //Circles may be scaled uniformly or non-uniformly and we should handle both cases when retessellating
    //1. scale diameter by max x or y scale
    //2. tessellate circle at this scale
    //3. inverse scale uniformly down to 1 scale
    //4. scale retessellated path by stretchMtx, this can be combined with multiplication by TR matrices as well.

    const circlePath = createAlignedNGonForCircle(diameterMM * stretchMax);
    const scaleMtx = multiplyMatrix33(transformMtx, invUniformScaleMtx); //Step 4, plus mult by TR matrices to back path in correct rotation and position for shapeshifter
    const scaledCirclePath = pathTransform(circlePath, scaleMtx);

    return [
      clonePath({
        ...scaledCirclePath,
        cutParams: svgGroup.basePathSet[0].cutParams,
      }),
    ];
  }

  //Not a circle, so handle as usual
  for (const basePath of svgGroup.basePathSet) {
    let { sourceSvg } = basePath;
    if (sourceSvg === undefined || typeof sourceSvg !== 'object') {
      TRSPathSet.push(basePath);
      continue;
    }
    //Split svg into multiple paths if needed
    //This only works with CutPaths and Paths, not SimplePolygons. But SimplePolygons don't have stored SVG right now, so it's ok.
    const pathRe = /[Mm][^Mm]+/gm;

    // We are caching the usvg converted SVG for each path, so we do not need to run it through the wasm parser again.
    // Instead, we can send pathCmds directly to the tessellation code in SvgOps.getPathPointsFrom USVGPathCmds below.
    const pathSegData = sourceSvg.svg?.match(pathRe);
    if (pathSegData) {
      for (const pathSeg of pathSegData) {
        // We need to determine a path scale to tessellate this svg to the appropriate level of detail.

        //First, find the matrix that converts from mm back to the svg viewbox units.
        const mtx = invert(sourceSvg.sourceTransform);

        //Use this matrix to get the size of the basePath in viewBox units
        const basePathViewBoxSize = getAABBSize(
          AABBTransform(basePath.AABB, mtx)
        );

        //Create a fake viewbox of this size for the sake of getViewboxUnitsMm
        const basePathFakeViewbox = new AABB({ size: basePathViewBoxSize });

        //Convert viewbox units to mm based on fake viewBox and actual basePath size. This tells us that 1 viewbox unit = X mm.
        const basePathViewboxMmPerUnit = SvgOps.getViewboxUnitsMm(
          getAABBSize(basePath.AABB),
          basePathFakeViewbox
        );

        //getPathScale returns the original path scale for this shape, but now we scale it by the stretchMtx to increase tesselation detail
        //If scale is 4x, then we need 4x finer tessellation. This ensures that "facet size" stays the same regardless of scale.

        // Making workspaceScale larger makes the tessellation detail higher. e.g. 4x larger needs 4x workspaceScale.
        const workspaceScale = Math.max(stretchMtx[0][0], stretchMtx[1][1]);

        const pathScale = getPathScale(
          scalarMul(basePathViewboxMmPerUnit, workspaceScale)
        );

        // Now, retessellate the path at the new level of detail.
        const pathPoints = getPathPointsFromUSVGPathCmds(
          pathSeg,
          pathScale,
          false
        );
        const retessellatedPath = new BasePath({ points: pathPoints });

        //Plain basePaths needs to be scaled by the sourceTransform to convert to mm
        const transformedRetessallatedPath = basePathTransform(
          retessellatedPath,
          sourceSvg.sourceTransform
        );
        if (Array.isArray(transformedRetessallatedPath)) {
          TRSPathSet.push(...transformedRetessallatedPath);
        } else {
          TRSPathSet.push(transformedRetessallatedPath);
        }
        // }
      }
    }
  }
  //Now clean/repair TRSPathSet
  const repairedPaths = repairPaths(TRSPathSet);

  //Finally, scale by TRSMtx, we defer this to the end so we don't have to scale path repair tolerances.
  return repairedPaths.map((basePath) =>
    basePathTransform(basePath, transformMtx)
  );
}

export {
  clone,
  createSVGGroupAndImportPositionFromSvg,
  createScaledBasePathSvg,
  createSvgGroupFromSvg,
  updateKeyAndValue,
  updateCutParams,
  updateSvgGroupTransform,
  updateTs,
  repairPaths,
  createStandaloneGroupSvg,
  getSanitizedSvgGroup,
  applyPostProcess,
  getTRSPathSet_deprecating,
};
